import React from 'react';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import './assets/scss/styles.scss'

export default function PhotoModal(props) {

    function _onHandleClose(){
        props.onClose && props.onClose(!props.modal);
    }

    const closeIcon = (
        <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    return (
        <Modal open={props.modal}
                onClose={() => _onHandleClose()}
                closeIcon={closeIcon}>
            <div className="modal-video">
                <img src={props.photo} alt="" />
            </div>
        </Modal>
    )
}

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getExploreChildrens, getExploreTitle, getParents, getHasInterview } from '../../assets/js/api';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Theme from './Theme';
import './assets/scss/styles.scss';
import Helmet from 'react-helmet';
import { cleanArray, colorShade, needsAlphabet, normalizeText, translate } from '../../assets/js/functions';
import NoResultsMessage from '../../components/Errors/NoResultsMessage';
import Selector from '../../components/Selector/Selector';

export default function Themes(props) {

    const breadcrumbsStatic = [
        {name: translate("Entrevistas"), url: "/interviews"},
        {name: translate("Explora"), url: "/interviews/explore"}
    ];
    const [id, setId]                       = useState(null)
    const [data, setData]                   = useState(null)
    const [title, setTitle]                 = useState(null)
    const [indexations, setIndexations]     = useState(null)
    const [isThirdTerm, setIsThirdTerm]     = useState(null)
    const [isFourthTerm, setIsFourthTerm]   = useState(null)
    const [color, setColor]                 = useState(null)
    const [alphabet, setAlphabet]           = useState("abcdefghijklmnopqrstuvwxyz".toUpperCase().split(""))

    useEffect(() => {
        setId(props.match.params.id);
        getChildrens();
        getExploreTitle(props.match.params.id).then(res => {
            if (res.data.result){
                !data && setIndexations(res.data.result[0]);
                setTitle(res.data.result[0].term);
                setIsThirdTerm(res.data.result[0].childrens && res.data.result[0].indexation ? true : false);
                setIsFourthTerm(!res.data.result[0].childrens && res.data.result[0].indexation ? true : false);
                getColor(props.match.params.id, res.data.result[0].term)
            }
        })
        getAlphabet()
    }, []);

    function getChildrens(char){
        getExploreChildrens(props.match.params.id, char).then(res => {
            const terms = res.data.result;
            const validTerms = [];
            const promise = terms.map(
                (term, index) => getHasInterview(term).then(res => {
                    if (res) {
                        validTerms.push(term);
                    }
                })
            )
            Promise.all(promise).then(
                res => {
                    setData(validTerms.sort((a, b) => {
                        return a.term.localeCompare(b.term)
                    }))
                }
            )

        })
    }

    function getAlphabet(){
        const promise = getExploreChildrens(props.match.params.id).then(
            res => res.data.result.map(
                term => { return normalizeText(term.term[0].toUpperCase()) }
            )
        )

        Promise.resolve(promise).then(
            chars => setAlphabet(cleanArray(chars).sort())
        )
    }

    function getColor(id, current){
        getParents(id).then(
            res => {setColor(res.data.result[0] ? normalizeText(res.data.result[res.data.result.length-1].term, true) : current && normalizeText(current, true))}
        )
    }

    if (props.themeId && id) props.themeId(props.match.params.id)

    if(id && data && title){
        return (
            <>
                <div id="themes">
                    <Breadcrumbs currentId={id} beforeData={breadcrumbsStatic} currentSite={title}/>
                    <h2>{title}</h2>
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                    {!isFourthTerm ?
                        <>
                            <Container>
                                { needsAlphabet(props.match.params.id) && alphabet && <Selector data={alphabet} onClick={(char) => getChildrens(char)}/> }
                                <Row className={isThirdTerm || isFourthTerm ? 'sub-levels' : ''}>
                                    {data && color
                                        ? data.map(
                                            (res, i) =>
                                                res.term !== "" && res.descriptor !== "no" ?
                                                    <Col key={res.term_id} xs={12} md={3} className={"theme-item"}>
                                                        <div className='line-skew' style={{background: colorShade(color, ((data.length-i)*12)), color: '#000'}}></div>
                                                        <Link to={"/interviews/explore/"+res.term_id}>{res.term}</Link>
                                                    </Col>
                                                : null
                                        ) : null
                                    }
                                    {!data[0] && <NoResultsMessage className="text-center py-2 card my-2"/>}
                                </Row>
                            </Container>
                            {isThirdTerm && (!indexations ? <Theme key={id} id={id}/> : <Theme key={id} id={id} indexations={indexations.indexation} title={title}/>)}
                        </>
                    :   !indexations ? <Theme key={id} id={id}/> : <Theme key={id} id={id} indexations={indexations.indexation} title={title}/>
                    }
                </div>
            </>
        )
    }
    return <div className="loader1"><div></div><div></div></div>;
}

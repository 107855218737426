import React, { useEffect, useState } from 'react';
import './assets/scss/styles.scss';
import { Container } from 'react-bootstrap';
import { victimNameDisplay, getCurrentLanguage, leafletIcon } from '../../assets/js/functions';
import { getSitiosMaps, getSitesVictims } from '../../assets/js/api';
import proj4 from 'proj4';

export default function MapSitios(props){

    var map = null;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.js";
        script.async = true;
        script.onload = () => scriptLoaded();
        document.body.appendChild(script);

        const style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
        document.head.appendChild(style);
    }, [])

    function filterVictims(elem, victims) {
        const center = elem.term_id;
        return victims.filter(value => {
            if (!value.movements_capture_data || value.movements_capture_data.length === 0) {
                return false;
            }
            let result = false;
            value.movements_capture_data.forEach(capture => {
                if (capture.detention_center_data && capture.detention_center_data.search('\"'+center+'\"') >= 0) {
                    result = true;
                }
                if (capture.origin_center_data && capture.origin_center_data.search('\"'+center+'\"') >= 0) {
                    result = true;
                }
                if (capture.destination_center_data && capture.destination_center_data.search('\"'+center+'\"') >= 0) {
                    result = true;
                }
            });
            return result;
        });
    }

    function scriptLoaded(){
        var L = window.L;
        map = new L.map(document.getElementById('mapa'));
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
        // Target's GPS coordinates.
        var target = L.latLng('40.416775', '-3.703790');
        map.setView(target, 6);

        const icon = leafletIcon();

        Promise.all([
            getSitesVictims(),
            getSitiosMaps(props.match.params.id)
        ])
        .then((res) => {
            setLoading(false);
            const victims = res[0];
            const campos = res[1];
            if (campos === null) {
                return;
            }
            campos.forEach((elem) => {
                elem.victims = filterVictims(elem, victims);
                if (elem.victims.length === 0) {
                    return;
                }
                var popup = L.popup();
                var popupContent = '';

                elem.victims.map(
                    victim => {
                        popupContent += "<li><a href=\"/"+getCurrentLanguage()+"/search/victim/"+victim.section_id+"/prision\" target='_blank'>"+victimNameDisplay(victim.ref_victim_name, victim.ref_victim_surname)+"</a></li>"
                    }
                )

                const target = L.latLng(elem.coordinates[1], elem.coordinates[0]);
                L.marker(target, {icon: icon})
                .addTo(map)
                .bindPopup(popup);
                popup.setContent('<h6>'+elem.term+'</h6><div class="marker-data-list"><ul class="popupMap">'+popupContent+'</ul></div>');
            });
        });
    }


    return (
        <Container>
            {loading?
                <div className="loader1"><div></div><div></div></div>
            :''}
            <div id="mapa"></div>
        </Container>
    )
}

import React from 'react';
/*import parse, { attributesToProps } from 'html-react-parser';*/
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCurrentLanguage } from '../../assets/js/functions';
import './assets/scss/styles.scss';


export default function Interviews(){

    return (
        <div id="learn-more">
            <Container>
                {
                    getCurrentLanguage() === 'es' ?
                        <span className='text'>
                            <span>Oroibidea, camino de memoria, sugiere que hay múltiples sendas que transitar en la recuperación de la memoria en Navarra. Una de las más importantes es el archivo de la memoria oral sobre el pasado traumático de Navarra, con más de 1.200 fragmentos de 161 entrevistas, procedentes de 14 <Link target={"_blank"} to={'/projects'} className='mark'>proyectos</Link> de investigación depositados en el Centro de Documentación del Instituto Navarro de la Memoria. Poco a poco irán incorporándose nuevos fragmentos de nuevos proyectos.</span><br/><br/>
                            <span>Todas las entrevistas son accesibles íntegramente, aunque se han creado fragmentos con contenidos destacables que son recuperables mediante la navegación por las palabras clave que los describen en el apartado <Link target={"_blank"} to={'/interviews/explore'} className='mark'>explora</Link>.</span><br/><br/>
                            <span> Aún así, las 108 horas de entrevistas han sido transcritas y pueden buscarse todas las palabras dichas por los testimonios en el cajón central de búsqueda, que también sugiere las palabras clave con las que se han indizado los fragmentos de entrevistas, accesibles a su vez a través del botón <Link target={"_blank"} to={'/interviews/explore'} className='mark'>explora</Link>.</span><br/><br/>
                            <span>Para facilitar la búsqueda de personas desaparecidas o asesinadas aludidas por los testimonios, estas son accesibles tanto desde el apartado reservado a las víctimas dentro de <Link target={"_blank"} to={'/interviews/explore'} className='mark'>explora</Link> como en el <Link target={"_blank"} to={'/map'} className='mark'>mapa de poblaciones de víctimas</Link>.</span><br/><br/>
                            <span>Para refinar la búsqueda mediante las palabras clave, aparecen relacionadas en los resultados aquellas otras palabras que combinan con el primer término seleccionado.</span><br/><br/>
                            <span>Todos los fragmentos llevan una indicación del período histórico al que se refieren para ubicar al usuario antes de la visualización. Igualmente es posible leer un resumen de cada entrevista o visualizarla completa.</span><br/>
                            <span>Las entrevistas completas también son accesibles vinculadas a cada uno de los <Link target={"_blank"} to={'/projects'} className='mark'>proyectos</Link> a los que pertenecen, y previa a su visualización pueden leerse su resumen e informaciones sobre las víctimas a las que aluden y su población de vecindad en caso de existir.</span><br/><br/>
                            <span><Link target={"_blank"} to={'/map'} className='mark'>El mapa de poblaciones de víctimas</Link> muestra todas las poblaciones donde vivían las víctimas a las que aluden los testimonios. Los contenidos son accesibles por población, y dentro de cada población existen referencias individuales a las víctimas con fragmentos de los testimonios que hablan de ellos y un enlace a su correspondiente ficha dentro del Fondo Documental de la Memoria Histórica de Navarra.</span><br/><br/>
                            <span>El archivo es bilingüe castellano-euskera. Las entrevistas se ofrecen en el idioma original, aunque en la navegación en castellano, las entrevistas en euskera están subtituladas en la lengua de navegación.</span><br/><br/>
                            <span> Los contenidos han sido estructurados mediante el tesauro del Instituto Navarro de la Memoria, herramienta viva que será publicada en un futuro indeterminado para su reutilización.</span><br/><br/>
                            <span>Para más información sobre el proyecto, pueden ponerse en contacto a través del correo electrónico: <a href="mailto:inm@navarra.es">inm@navarra.es</a></span>
                        </span>
                    :
                        <span className='text'>
                            <span>Oroibidea, oroimenaren bidea: Nafarroan memoria berreskuratzeko hainbat bide daudela iradokitzen da hitz honetan. Garrantzitsuenetako bat Nafarroaren iragan traumatikoari buruzko ahozko memoriaren artxiboa da, 161 elkarrizketatik 1.235 zatirekin, Nafarroako Memoriaren Institutuaren Dokumentazio Zentroan gordetako 14 <Link target={"_blank"} to={'/projects'} className='mark'>Ikerketa-proiektutatik</Link> datozenak. Apurka-apurka, proiektu berrietatik zati berriak gehitzen joango dira.</span><br/><br/>
                            <span>Elkarrizketa guztiak osorik eskura daitezke, baina eduki azpimarragarriak dituzten zatiak sortu dira, eta horiek <Link target={"_blank"} to={'/interviews/explore'} className='mark'>Esploratu</Link> atalean deskribatzen diren hitz gakoetan nabigatuz berreskura daitezke.explora.</span><br/><br/>
                            <span> Hala ere, elkarrizketen 108 orduak transkribatu egin dira, eta testigantzek esandako hitz guztiak bilaketa-kaxoi nagusian bila daitezke. Kaxoi horrek elkarrizketa-zatiak indexatzeko erabili diren gako-hitzak ere iradokitzen ditu, eta horiek <Link target={"_blank"} to={'/interviews/explore'} className='mark'>Esploratu</Link> botoiaren bidez eskura daitezke.</span><br/><br/>
                            <span>Lekukotzek aipatzen dituzten desagertutako edo eraildako pertsonen bilaketa errazteko, lekukotza horiek eskuragarri daude, bai <Link target={"_blank"} to={'/interviews/explore'} className='mark'>Esploratu</Link> atalaren barruan biktimentzat gordetako atalean, bai <Link target={"_blank"} to={'/map'} className='mark'>Biktimen populazioen mapan.</Link>.</span><br/><br/>
                            <span>Bilaketa gako-hitzen bidez fintzeko, hautatutako lehen terminoarekin konbinatzen diren beste hitz batzuk agertzen dira emaitzetan.</span><br/><br/>
                            <span>Zati guztiek adierazten dute zein aldi historikori buruz ari diren erabiltzailea bistaratu aurretik kokatzeko. Halaber, posible da elkarrizketa bakoitzaren laburpena irakurtzea edo osorik ikustea.</span><br/>
                            <span>Elkarrizketa osoak ere <Link target={"_blank"} to={'/projects'} className='mark'>Proiektu</Link> bakoitzarekin lotuta daude, eta, ikusi aurretik, aipatzen dituzten biktimei buruzko laburpena eta informazioak irakur daitezke, bai eta, halakorik badago, haien auzo-populazioa ere.</span><br/><br/>
                            <span><Link target={"_blank"} to={'/map'} className='mark'>Biktimen populazioen mapak</Link> lekukotzek aipatzen dituzten biktimak bizi ziren herri guztiak erakusten ditu. Edukiak biztanleek eskura ditzakete, eta biztanleria bakoitzaren barruan biktimei buruzko erreferentzia indibidualak daude, horiei buruz hitz egiten duten lekukotasunen zatiekin eta Nafarroako Oroimen Historikoaren Dokumentu Funtsaren barruan dagokion fitxarako esteka batekin.</span><br/><br/>
                            <span>Artxiboa gaztelania-euskara elebiduna da. Elkarrizketak jatorrizko hizkuntzan eskaintzen dira, baina gaztelaniazko nabigazioan, euskarazko elkarrizketak nabigazio-hizkuntzan azpititulatuta daude.</span><br/><br/>
                            <span>Edukiak Nafarroako Memoriaren Institutuaren tesauroaren bidez egituratu dira. Tresna bizi hori etorkizun zehaztugabean argitaratuko da, berrerabiltzeko.</span><br/><br/>
                            <span>Proiektuari buruzko informazio gehiago nahi izanez gero, jarri harremanetan posta elektronikoaren bidez: <a href="mailto:inm@navarra.es">inm@navarra.es</a></span>
                        </span>
                    }
            </Container>
        </div>
    )

}

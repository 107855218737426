import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { BsMenuButtonWide } from 'react-icons/bs';
import { getInterviews, getProject } from '../../assets/js/api';
import { translate } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import InterviewProject from '../../components/Interview/InterviewProject';
import parse from 'html-react-parser';
import Helmet from 'react-helmet';
import './assets/scss/styles.scss';


export default function Project(props){

    const [projectId, setProjectId]         = useState(null);
    const [projectData, setProjectData]     = useState(null);
    const [data, setData]                   = useState(null);
    const breadcrumbsStatic                 = [
        {name: translate("Entrevistas"), url: "/interviews"},
        {name: translate("Proyectos"), url: "/interviews/projects"}
    ];

    useEffect(() => {
        if (props.match){
            const projectId = props.match.params.id;
            if(projectId){
                setProjectId(projectId);
                getProject(projectId).then(res => {
                    setProjectData(res.data.result[0] ? res.data.result[0] : null)
                });
                getInterviews(projectId).then(res => {
                    setData(res.data.result ? res.data.result : null)
                });
            }
        }
    }, []);

    return (
            data && projectData ?
                <div id="project">
                    <Breadcrumbs key={projectId} staticData={breadcrumbsStatic} currentSite={projectData.name}/>
                    <>
                        <h2>{projectData.name}</h2>
                        <Helmet>
                            <title>{projectData.name}</title>
                        </Helmet>
                        <Container className="description">
                            <h4 className='title'>
                                {translate('Resumen')}:
                            </h4>
                            { parse(projectData.description) }
                        </Container>
                        <Container className="interviews">
                            <h3 className="title">{ translate('Testimonios') } - {data.length} {translate('resultados')}</h3>
                            <Row>
                                <Col lg={12} className="interviews-cards">
                                    {
                                        data.map(
                                            (res, i) => <InterviewProject isProject key={i} data={res}/>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </>
                </div>
            : <div className="loader1"><div></div><div></div></div>
    )
}

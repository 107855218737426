import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { getFragmentfromIndexLocator, getTermsCombined, getThematicIndexations } from '../../assets/js/api';
import { translate, cleanArray } from '../../assets/js/functions';
import CombineFilter from '../../components/CombineFilter/CombineFilter';
import CombineItems from '../../components/CombineFilter/CombineItems';
import Interview from '../../components/Interview/Interview';
import './assets/scss/styles.scss';

export default class Theme extends Component{

    constructor(props){
        super(props);
        this.state = {
            interviews:         [],
            indexations:        [],
            filteredInterviews: [],
            combineTerm:        [],
            combineFilterItems: [],
            isEmpty:            false,
            currentFilter:      [],
            loaded:             false
        }
    }

    componentDidMount(){
        this.fetchData()
    }


    async fetchData(){
        let indexations = this.state.indexations && !this.state.indexations.length ? this.props.indexations : this.state.indexations;

        this.setState({interviews: [], combineFilterItems: []})

        if (indexations){
            const promise = JSON.parse(indexations).map(
                i => {
                        return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                            interview => {
                                if (interview.data.result[0] && interview.data.result[0].result && interview.data.result[0].result.terms){
                                    interview.data.result[0].result.terms.map(
                                        term => this.setState({
                                            combineFilterItems: [...this.state.combineFilterItems, [term.term_id, term.term]]
                                        })
                                    )
                                }
                                return [interview.data.result[0].result, interview.data.result[1].result[0]];
                            }
                        )
                    }
            )
            Promise.all(promise).then(
                response => {
                    const result = response.filter(value =>
                        typeof value[0] !== 'undefined' && typeof value[1] !== 'undefined'
                    );
                    this.setState({interviews: result, loaded: true})
                }
            )
        }else{
            getThematicIndexations(this.props.id).then(res => {
                const promise = JSON.parse(res.data.result[0].indexation).map(
                    i => {
                        return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                            interview => {
                                if (interview.data.result[0] && interview.data.result[0].result && interview.data.result[0].result.terms){
                                    interview.data.result[0].result.terms.map(
                                        term => this.setState({
                                            combineFilterItems: [...this.state.combineFilterItems, [term.term_id, term.term]]
                                        })
                                    )
                                }
                                return [interview.data.result[0].result, interview.data.result[1].result[0]];
                            }
                        )
                    }
                )
                Promise.all(promise).then(
                    response => {
                        const result = response.filter(value =>
                            typeof value[0] !== 'undefined' && typeof value[1] !== 'undefined'
                        );
                        this.setState({interviews: result, loaded: true})
                    }
                )
            })
        }
    }

    _combineIndexations(item, remove = false){
        let termsArr = [];
        termsArr.push(this.props.id)
        !!this.state.combineTerm.length && this.state.combineTerm.map(
            term => termsArr.push(term[0])
        )
        termsArr.push(item[0]);
        termsArr = cleanArray(termsArr);
        if (remove){
            const arr = (termsArr, i) => termsArr.filter(el => el !== i);
            termsArr = arr(termsArr, item[0]);
        }

        if ((termsArr[1] && termsArr[2]) && termsArr[1] === termsArr[2]){
            termsArr = [];
        }

        getTermsCombined(termsArr).then(
            (res) => res.data.result.map(
                result => {
                    this.setState({indexations: result.indexation})
                }
            )
        ).finally(
            () => this.fetchData()
        )
    }

    _addCombineTerm(item){

        document.getElementsByClassName("interviews")[0].scrollTo({ top: 0, behavior: "smooth" });
        if (!!this.state.combineTerm) this.setState({combineTerm: [...this.state.combineTerm, [item[0], item[1]]]});
        else this.setState({combineTerm: [item[0], item[1]]});

        this._combineIndexations(item);
    }

    _removeCombineTerm(term){

        this._combineIndexations(term, true);

        var arr = this.state.combineTerm;
        const removeItem = (arr, i) => arr.filter(el => el !== i);
        removeItem(arr, term);

        var filteredArray = arr.filter(function(item){ return item[0] !== term[0] })
        this.setState({combineTerm: filteredArray});

    }

    _usingTerms(term){
        this.setState({currentFilter: term})
    }


    render(){
        return (
            <div id="theme">
                <Container className={"interviews"+(this.props.thirdTerm ? " third-term" : "")}>
                    {
                        !!this.state.combineTerm.length && <CombineItems items={this.state.combineTerm} onRemove={(term) => this._removeCombineTerm(term)}/>
                    }
                    { !this.state.loaded && <div className="loader1"><div></div><div></div>Cargando</div> }
                    <Row className=''>
                        <h3 className="title">{ translate('Testimonios') } - {this.state.interviews.length} {translate('resultados')} </h3>
                        <Col lg={this.state.isEmpty ? 12 : 8} className="interviews-cards" key={this.state.combineTerm}>
                            {
                                this.state.interviews ? cleanArray(this.state.interviews).map(
                                    (res, i) => {
                                        return <Interview key={i} data={res[0]} info={res[1]}/>
                                    }
                                ) : <div className="loader1"><div></div><div></div></div>
                            }
                        </Col>
                        { !this.state.isEmpty && !!this.state.combineFilterItems.length ?
                            <Col lg={4} className="combine-themes">
                                <CombineFilter key={this.state.combineFilterItems} items={!!this.state.combineTerm.length && !!this.state.currentFilter.length ? this.state.currentFilter.sort() : this.state.combineFilterItems.sort()} inUseTerms={this.state.combineTerm} term={(item) => this._addCombineTerm(item)} whenEmpty={() => this.setState({isEmpty: true}) } />
                            </Col>
                        : null}
                    </Row>
                </Container>
            </div>
        )
    }
}

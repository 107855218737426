import React from 'react';
import Logo from '../../assets/images/Logo-INM-inverted.png'
import LogoGN from '../../assets/images/logo GOB + Agenda 2030-B-01.png'
import './assets/scss/styles.scss';
import { getCurrentLanguage } from '../../assets/js/functions';

export default function Footer() {

  return <>
            <div className='site-footer'>
                <a href={'https://www.navarra.es/'+getCurrentLanguage()} target="_blank" rel="noreferrer">
                    <img src={LogoGN} alt=""/>
                </a>
                <a href={'https://pazyconvivencia.navarra.es/'+getCurrentLanguage()+'/memoria'} target="_blank" rel="noreferrer">
                    <img src={Logo} alt=""/>
                </a>
            </div>
        </>;
}

import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { cleanArray, getTypeByTermId, translate } from '../../assets/js/functions';

export default function CombineFilter(props) {

    const [isEmpty, setIsEmpty] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    if (!props.items || !!!props.items) setIsEmpty(true)

    useEffect(() => {
        setCurrentId(window.location.pathname.split("/explore/")[1]);
    }, [])

    props.items && props.items.length <= 0 && props.whenEmpty && props.whenEmpty(true)

    if(props.items){
            return(
                <div className='combine-container'>
                    <h3 className='title'>{translate('Combina con')}:</h3>
                    <Container>
                        {cleanArray(props.items).map(
                            res => {
                                let render = true;
                                if (res[0] === currentId) render = false;
                                if (props.inUseTerms) props.inUseTerms.map(
                                    (term) => {
                                        if (res[0] === term[0]) render = false;
                                        return null
                                    }
                                )
                                if (render && !res[0].includes('peri1_')){
                                    return <div className='theme-item full' key={res[0]}>
                                                <div className={"line-skew "+getTypeByTermId(res[0])}></div>
                                                <button onClick={() => props.term && props.term(res)} className={'btn btn-combine'}>
                                                    {res[1]}
                                                </button>
                                            </div>
                                }
                                return null
                            }
                        )}
                    </Container>
                </div>
            );
    }else{
        if (isEmpty){
            props.whenEmpty && props.whenEmpty(true)
            return null
        }
        return <div className="loader1"><div></div><div></div></div>
    }
}

import React, { useEffect } from 'react';
import './assets/scss/styles.scss';
import { Container } from 'react-bootstrap';
import { victimNameDisplay, getCurrentLanguage, groupBy, translate } from '../../assets/js/functions';
import { getFosasMapsMarks } from '../../assets/js/api';
import proj4 from 'proj4';

export default function MapFosas(props){

    var map = null;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sitna.tracasa.es/api/";
        script.async = true;
        script.onload = () => scriptLoaded();

        document.body.appendChild(script);
    }, [])

    function scriptLoaded(){
        var SITNA = window.SITNA;

        proj4.defs('EPSG:25830', "+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs");
        proj4.defs('EPSG:4326', "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");

        map = new SITNA.Map("mapa", {
            baseLayers: [
                SITNA.Consts.layer.IDENA_DYNBASEMAP
            ],
            defaultBaseLayer: SITNA.Consts.layer.IDENA_DYNBASEMAP,
            controls: {
                overviewMap: {
                    layer: SITNA.Consts.layer.IDENA_DYNBASEMAP
                }
            }
        });

        getFosasMapsMarks(props.match.params.id).then((data) => {
            const arr = data.filter(function(value) {
                return value.location !== undefined && value.location !== null;
            });

            map.loaded(function () {
                arr.map(res => {
                    let popupContent = '';
                    res.documented_deceased.forEach(victim => {
                        popupContent += "<li><a href=/"+getCurrentLanguage()+"/search/victim/"+victim.section_id+" target='_blank'>"+victimNameDisplay(victim.name, victim.surname)+"</a></li>"
                    });

                    const content = '<div class="map-popup-content"><h6><a href="/'+getCurrentLanguage()+'/search/fosa/'+res.section_id+'">'+res.title+'</a></h6><div class="marker-data-list"><ul class="popupMap">'+popupContent+'</ul></div></div>'

                    const locUTM = proj4('EPSG:4326', 'EPSG:25830', res.location);
                    map.addMarker(locUTM, {
                        data: content,
                        width: 22,
                        cssClass: "tc-marker2"
                    })

                })
            })
        })
    }


    return (
        <Container>
            <div id="mapa"></div>
        </Container>
    )
}

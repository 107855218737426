import React from 'react'
import { translate } from '../../assets/js/functions'

function NoResultsMessage(props) {
    return (
        <div className={'no-result-message'+(props.className ? " "+props.className : "")}>
            {props.icon ? props.icon : null}
            <span className='message'>{ props.text ? props.text : translate("No hay ningún resultado")}</span>
        </div>
    )
}

export default NoResultsMessage;

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { FaSearch } from "react-icons/fa";
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate, victimNameDisplay } from '../../assets/js/functions';
import { getSearchVictimsAdv } from '../../assets/js/api';

export default function SearchAdv(props){

    const title = translate("Búsqueda avanzada");
    const [victimsList, setVictimsList] = useState(null);
    const [id, setId] = useState(null);
    const [fondo, setFondo] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [searchArrayText, setSearchArrayText] = useState({
        fondo: '',
        name: '',
        surname: '',
        genero: '',
        nacimiento: '',
        residencia: '',
        defuncion: '',
    });
    const [search, setSearch] = useState({
        name: '',
        surname: '',
        genero: '',
        nacimiento: '',
        residencia: '',
        defuncion: '',
    });

    useEffect(() => {
        if(props.match){
            const id = props.match.params.id;
            setId(id);
        }
    }, []);

    function handleSubmit(event) {
        setSearchText(Object.values(searchArrayText).filter(n => n !== '').join(', '));

        getSearchVictimsAdv(fondo, search).then(res => {
            if (res === null) {
                setVictimsList(null);
                return;
            }

            const result = res.map(value => {
                if (value.table === 'informant') {
                    value.ref_victim_name = value.name;
                    value.ref_victim_surname = value.surname;
                    value.victim = value.section_id;
                } else if (value.table === 'deportation_victims_census') {
                    value.ref_victim_name = value.ref_nom;
                    value.ref_victim_surname = value.ref_cognom;
                    value.victim = JSON.parse(value.deportat)[0];
                } else {
                    value.victim = JSON.parse(value.victim)[0];
                }
                return value;
            });
            setVictimsList(result);
        });
        event.preventDefault();
    }

    function handleFondoChange(event) {
        const target = event.target;
        let tmpText = searchArrayText;
        tmpText.fondo = target.getAttribute("data-text");
        setVictimsList(null);
        setFondo(target.value);
        setSearchArrayText(tmpText);
    }

    function handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let tmp = search;
        let tmpText = searchArrayText;

        if (target.tagName == 'SELECT') {
            const value = event.target.value;
            tmp[name] = value;
            if (value !== '') {
                tmpText[name] = target.options[target.selectedIndex].text;
            } else {
                tmpText[name] = '';
            }
        } else {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            tmp[name] = value;
            tmpText[name] = value;
        }
        setSearch(tmp);
        setSearchArrayText(tmpText);
    }

    return (
        <div className="content" id="searchadv">
            <Breadcrumbs key={id} currentSite={title}/>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container>

            <div>
            <h3 className="title-filter">{translate('Filtrar por')}</h3>
            <Link className="active btn button btn-cmn filter1" to={'/search/adv/'}>{translate('Víctimas')}</Link>
            <Link className="btn button btn-cmn filter2" to={'/search/adv/sites/'}>{translate('Lugares de represión')}</Link>
            </div>
            <form className="searcheradv" onSubmit={handleSubmit}>
                <ul className="dbfilter">
                    <li className="input">
                        <label>
                            <input value="all" data-text={translate('Todas')} type="radio" defaultChecked={fondo==='all'} name="font" onChange={handleFondoChange} />
                            {translate('Todas')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="golpe" data-text={translate('Víctimas mortales')} type="radio" defaultChecked={fondo==='golpe'} name="font" onChange={handleFondoChange} />
                            {translate('Víctimas mortales')}
                        </label>
                    </li>
                    <li className="input long">
                        <label>
                            <input value="economica" data-text={translate('Víctimas represión económica')} type="radio" defaultChecked={fondo==='economica'} name="font" onChange={handleFondoChange} />
                            {translate('Víctimas represión económica')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="exilio" data-text={translate('Exiliados/as')} type="radio" defaultChecked={fondo==='exilio'} name="font" onChange={handleFondoChange} />
                            {translate('Exiliados/as')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="deportacion" data-text={translate('Deportados/as')} type="radio" defaultChecked={fondo==='deportacion'} name="font" onChange={handleFondoChange} />
                            {translate('Deportados/as')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="presos" data-text={translate('Presos/as')} type="radio" defaultChecked={fondo==='deportacion'} name="font" onChange={handleFondoChange} />
                            {translate('Presos/as')}
                        </label>
                    </li>
                </ul>
                <Row>
                    <Col xl={4} lg={6} className="input">
                        <input type="text" name="name" onChange={handleInputChange} placeholder={translate('Nombre')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                        <input type="text" name="surname" onChange={handleInputChange} placeholder={translate('Apellidos')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <select name="genero" onChange={handleInputChange}>
                        <option value="">{translate('Género')}</option>
                        <option value="Mujer">{translate('Mujer')}</option>
                        <option value="Hombre">{translate('Hombre')}</option>
                    </select>
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <input type="text" name="nacimiento" onChange={handleInputChange} placeholder={translate('Municipio de nacimiento')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <input type="text" name="residencia" onChange={handleInputChange} placeholder={translate('Municipio de residencia')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <input type="text" name="defuncion" onChange={handleInputChange} placeholder={translate('Municipio de defunción')} />
                    </Col>
                </Row>
                <button type="submit" className="button"><FaSearch/></button>
            </form>

            {(victimsList !== null)?
            <div className="result-list">
                <h3>{searchText}</h3>
                <h4>{victimsList.length} { translate("resultados")} </h4>
                <Row className="victims-list">
                {victimsList.map(function(object, i){
                    return <Col md={6} lg={4}><Link to={'/search/victim/'+object.victim} key={i} >{victimNameDisplay(object.ref_victim_name, object.ref_victim_surname)}</Link></Col>
                })}
                </Row>
            </div>
            :''}

            </Container>


        </div>
        );
}

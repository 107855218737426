import React, { useState, useEffect } from 'react'
import { Alert, Container } from 'react-bootstrap';
import { getFreeSearch, getTermName } from '../../assets/js/api';
import './assets/scss/styles.scss';
import SearchResult from '../../components/SearchResult/SearchResult';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate, extractNumber } from '../../assets/js/functions';

export default function Search(props){

    const [data, setData]   = useState([]);
    const param = window.location.search.split("?query=")[1];
    const [count, setCount] = useState(0)
    const [empty, setEmpty] = useState(false)
    const breadcrumbsStatic                 = [
        {name: translate("Entrevistas"), url: "/interviews"},
    ];


    useEffect(() =>{
        if(props.match){
            const id = props.match.params.id;
            if (id){
                getTermName(id).then(res => setData(res.data.result[0]));
            }
        }
        if (param){
            let counter = 0;
            getFreeSearch(param).then(res => {
                res.data.result ? !res.data.result.length ?
                setEmpty(true)
                : res.data.result.map( val => {
                    if (val && val.project){
                        if (extractNumber(val.project) !== 25){
                            counter++;
                            setData(data => [...data, val])
                        }
                    }
                 } ) : setData([])
                setCount(counter);
            });
        }
    }, []);

    return (
        <div id="search">
            { param ?
            <>
                <Breadcrumbs staticData={breadcrumbsStatic} currentSite={translate("Buscador")}/>
                <h2>{count > 0 && count} { translate("resultados para")+":"} <em>{decodeURI(param)}</em></h2>
                <Container>
                    {
                        empty ? <Alert variant='warning'>{ translate('No hay resultados') }</Alert> :
                        data && !!data.length ? data.map(res => {
                                return <SearchResult key={res.av_section_id} data={res}/>
                        }): <div className="loader1"><div></div><div></div></div>
                    }
                </Container>
            </>
            :
                data ?
                    <h2><em>{data.term}</em></h2>
                : null
            }
        </div>
    )

}

import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import { Col, Row } from 'react-bootstrap'
import Modal from "react-responsive-modal";
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { translate } from '../../assets/js/functions';
import { domain, getVictimPhotos } from '../../assets/js/api';

export default function VictimPhotos(props) {

    const [victimId, setVictimId]     = useState(null);
    const [data, setData]     = useState(null);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        setVictimId(props.victimid);
        getVictimPhotos(props.victimid).then(res => {
          setData(res.data.result ? res.data.result : null) }
      );
    }, [])

    const closeIcon = (
      <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    return dataReturn();

    function dataReturn(){
        return (
          data && data.length > 0 ?
            <li>
            <button onClick={() => setOpen(o => !o)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><g fill="none" fillRule="evenodd"><path fill="#A3233B" fillRule="nonzero" d="M9.655 4.684 9.12 6.286H5.286A3.289 3.289 0 0 0 2 9.57v13.143A3.289 3.289 0 0 0 5.286 26H25a3.289 3.289 0 0 0 3.286-3.286V9.571A3.289 3.289 0 0 0 25 6.286h-3.835l-.534-1.602A2.457 2.457 0 0 0 18.295 3h-6.304a2.457 2.457 0 0 0-2.336 1.684Zm5.488 16.387a4.93 4.93 0 0 1-4.929-4.928 4.93 4.93 0 0 1 4.929-4.929 4.93 4.93 0 0 1 4.928 4.929 4.93 4.93 0 0 1-4.928 4.928Z"/><path d="M0 0h30v30H0z"/></g></svg>
            {translate('Fotografía')}
            </button>
            <Modal open={open}
                onClose={closeModal}
                closeIcon={closeIcon}>
              <div className="modal-popup">
                <div className="header">
                  {translate('Fotografía')}
                </div>
                <div className="content">
                      {data.map(
                          (res, i) =>
                            <div className='victim-ref-list'>
                              <Row>
                                <Col md={2}>
                                  <a className="img" target="blank_" href={domain+res.identify_image}><img src={domain+res.identify_image} /></a>
                                </Col>
                                <Col md={10}>
                              {(res.identify_image_caption)?
                              <p>
                              <strong>{translate('Descripción')}</strong>: {res.identify_image_caption}
                              </p>
                              :''}
                              {(res.documentary_found && res.documentary_found_data)?
                              <p>
                              <strong>{translate('Procedencia')}</strong>: <Link target="_blank" to={"/docs/"+JSON.parse(res.documentary_found_data)[0]}>{res.documentary_found}</Link>
                              </p>
                              :''}
                                </Col>
                              </Row>
                            </div>
                      )}
                </div>
              </div>
            </Modal>
          </li>
          :''
        );
    }

}
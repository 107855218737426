import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './assets/scss/styles.scss';
import { FaSearch } from "react-icons/fa";
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate } from '../../assets/js/functions';
import { getDocsByPoblacio, getDocsCatalogs } from '../../assets/js/api';
import Doc from '../../components/Docs/Doc';

export default function DocsSearch(props){

    const titleBreadcrum = translate("Documentos por población");
    const [docsList, setDocsList] = useState(null);
    const [fondosList, setFondosList] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchArrayText, setSearchArrayText] = useState({
        poblacion: '',
        fondo: '',
        tipo: ''
    });
    const [search, setSearch] = useState({
        poblacion: '',
        fondo: '',
        tipo: ''
    });


    useEffect(() => {
        getDocsCatalogs().then(res => {
            setFondosList(res.data.result)
        });
    }, []);

    function handleSubmit(event) {
        setSearchText(Object.values(searchArrayText).filter(n => n !== '').join(', '));
        if (search.poblacion === '' && search.fondo === '' && search.tipo === '') {
            setDocsList(null);
            return;
        }
        setDocsList(null)
        getDocsByPoblacio(search).then(res => {
            if (!(res.data.result)) {
                setDocsList(null);
                return;
            }
            setDocsList(res.data.result);
        });
        event.preventDefault();
    }

    function handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let tmp = search;
        let tmpText = searchArrayText;

        if (target.tagName == 'SELECT') {
            const value = event.target.value;
            tmp[name] = value;
            if (value !== '') {
                tmpText[name] = target.options[target.selectedIndex].text;
            } else {
                tmpText[name] = '';
            }
        } else {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            tmp[name] = value;
            tmpText[name] = value;
        }
        setSearch(tmp);
        setSearchArrayText(tmpText);
    }

    const title = translate("Documentos por población");
    return (
        <div className="content" id="search-docs">
            <Breadcrumbs currentSite={titleBreadcrum}/>
            <h2>{title}</h2>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container>

            {(fondosList !== null)?
            <form className="searcheradv searcherdocs" onSubmit={handleSubmit}>
            <Row>
                <Col lg={{span: 8, offset: 2 }} sm={{span: 12 }}>
                    <Row>
                        <Col sm={10} className="input">
                            <input type="text" name="poblacion" onChange={handleInputChange} placeholder={translate('Población')} />
                        </Col>
                        <Col sm={2} className="input">
                            <button type="submit" className="button"><FaSearch/></button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="input">
                        <select name="fondo" onChange={handleInputChange}>
                            <option value="">{translate('Fondo')}</option>
                            {fondosList.map(val => {
                                return <option value={val.section_id}>{val.collection_archive}</option>
                            })}
                        </select>
                        </Col>
                        <Col md={6} className="input">
                        <select name="tipo" onChange={handleInputChange}>
                            <option value="">{translate('Tipo de documento')}</option>
                            <option value="Pre_fotografía">{translate('Fotografía')}</option>
                            <option value="Pre_documento">{translate('Documento')}</option>
                            <option value="Pre_audiovisual">{translate('Audiovisual')}</option>
                        </select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </form>
            :''}

            {(docsList !== null)?
            <div className="result-list">
                <h3>{searchText}</h3>
                <h4>{docsList.length} { translate("resultados")} </h4>
                <Row className="docs-list">
                    {docsList.map(function(object, i){
                        return <Col md={4} lg={3} className="docs-card"><Doc key={i} data={object}/></Col>
                    })}
                </Row>
            </div>
            :''}

            </Container>


        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import { getSearchVictims } from '../../assets/js/api';
import { translate, victimNameDisplay } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import './assets/scss/styles.scss';

export default function SearchVictims(props){

    const titleBreadcrum    = translate("Búsqueda victimas");
    const param = decodeURIComponent(window.location.search.split("?query=")[1]);
    const [text, setText]         = useState(null);
    const [victimsData, setVictimsData]     = useState(null);
    const [empty, setEmpty]     = useState(null);

    useEffect(() => {
        if (param){
            setText(param);

            getSearchVictims(param).then(res => {
                var values = (res.data.result && res.data.result.length > 0) ? res.data.result : null;
                if (res.data.result.length == 0) {
                    setEmpty(true);
                }
                setVictimsData(values);
            })
        }
    }, []);

    return (
        <div className="content" id="search-victims">
            <Breadcrumbs  currentSite={titleBreadcrum}/>
            <>
                <Helmet>
                    <title>{titleBreadcrum}</title>
                </Helmet>
                <h2>{text}</h2>

                <Container>
                    {empty?<Alert variant='warning'>{ translate('No hay resultados') }</Alert>:''}
                    {(victimsData !== null)?
                    <div className="result-list">
                        <h4>{victimsData.length} { translate("resultados")} </h4>
                        <Row className="victims-list">
                        {victimsData.map(function(object, i){
                            return <Col md={6} lg={4}><Link to={'/search/victim/'+object.section_id} key={i} >{victimNameDisplay(object.name, object.surname)}</Link></Col>
                        })}
                        </Row>
                    </div>
                    :''}
                </Container>
            </>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getDocsCatalogs } from '../../assets/js/api'
import Docs from './Docs';
import './assets/scss/styles.scss';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate } from '../../assets/js/functions';

export default function DocsList(props){

    const [data, setData]   = useState(null);
    const title             = translate("Fondos documentales");
    const [id, setId]       = useState(null);

    useEffect(() => {
        if(props.match){
            const id = props.match.params.id;
            setId(id);
            /*if (id){
                getTermName(id).then(res => setData(res.data.result));
            }*/
        }
        getDocsCatalogs().then(res => setData(res.data.result));
    }, []);

    return (
        <div id="projects">
            {!id ?
                <>
                    <Breadcrumbs key={id} currentSite={title}/>
                    <h2>{title}</h2>
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                    <Container>
                        <Row>
                            {docsCatalog()}
                        </Row>
                    </Container>
                </>
            :
                <Docs {...props} />
            }
        </div>
    );

    function docsCatalog(){
        if (data)
            return data.map(
                res =>
                    <Col key={res.section_id} xs={12} md={3} className="theme-item project-item">
                        <Link className='btn-cmn' to={"/docs/"+res.section_id}>{res.collection_archive}</Link>
                    </Col>
            );
        else return <div className="loader1"><div></div><div></div></div>
    }
}

import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from 'react-router-dom';
import { getCurrentLanguage, getLanguages, translate } from "../../assets/js/functions";
import Menu from "../../components/Menu/Menu"

import Header from "../Header/Header";

import Wip from "../../views/Wip/Wip";

import Home from "../../views/Home/Home";
import Maps from "../../views/Maps/MapsList";

import Interviews from "../../views/Interviews/InterviewsList";
import Projects from "../../views/Projects/ProjectsList";
import Explore from "../../views/Explore/Explore";
import Search from "../../views/Search/Search";

import Docs from "../../views/Docs/DocsList";
import DocsSearch from "../../views/Docs/DocsSearch";


import Themes from "../../views/Themes/Themes";
import SearchVictim from "../../views/Search/Victim";
import SearchVictims from "../../views/Search/Victims";
import SearchPoblacio from "../../views/Search/poblacion";
import SearchAdv from "../../views/Search/SearchAdv";
import SearchAdvSites from "../../views/Search/SearchAdvSites";
import SearchFosa from "../../views/Search/Fosa";
import LearnMore from "../../views/LearnMore/LearnMore";
import Expositions from "../../views/Expositions/Expositions";


export default function Section(props){

    const [menu = false, setMenu] = useState(false);
    const [path, setPath] = useState(null);
    const history = useHistory();
    const [language, setLanguage] = useState(getCurrentLanguage());

    useEffect(() => {
        setPath(history.location.pathname)
    }, [history.location.pathname, language])

    function _stateMenu(state = !menu){
        setMenu(state);
        props.openMenu && props.openMenu(state);
    }

    /*
    return (
        <>
        <div className={"app-content"}>
            <div className="bg-image"></div>
            <Header className="header" inverted open={(val) => _stateMenu()}/>
            <Switch>
                <Route exact path={'*'} render={props => <Wip/>} />
            </Switch>
        </div>

        </>
    );
    */


    return (
        <>
        <div className={"app-content"}>
            <div className="bg-image"></div>
            <Header className="header" inverted open={(val) => _stateMenu()}/>
            <Switch>
                <Route exact path={'/'} render={props => <Home/>} />

                <Route exact path={'*/maps'} render={props => <Maps/>} />
                <Route exact path={'*/maps/:id'} render={props => <Maps {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/learn-more'} render={props => <LearnMore/>} />
                <Route exact path={'*/expositions'} render={props => <Expositions/>} />

                <Route exact path={'*/interviews'} render={props => <Interviews/>} />
                <Route exact path={'*/interviews/projects'} render={props => <Projects/>} />
                <Route exact path={'*/interviews/projects/:id'} render={props => <Projects {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/interviews/explore/:id'} render={props => <Themes {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/interviews/explore'} render={props => <Explore {...props}/>} />
                <Route exact path={'*/interviews/search/:id'} render={props => <Search {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/interviews/search*'} render={props => <Search/>} />

                <Route exact path={'*/docs'} render={props => <Docs/>} />
                <Route exact path={'*/docs/search'} render={props => <DocsSearch/>} />
                <Route exact path={'*/docs/:id'} render={props => <Docs {...props} key={props.match.params.id}/>} />


                <Route exact path={'*/search/victims'} render={props => <SearchVictims/>} />
                <Route exact path={'*/search/victim/:id'} render={props => <SearchVictim {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search/victim/:id/:group'} render={props => <SearchVictim {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search/fosa/:id'} render={props => <SearchFosa {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search/adv/sites/:group/:id'} render={props => <SearchAdvSites {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search/adv/sites/:group'} render={props => <SearchAdvSites {...props}/>} />
                <Route exact path={'*/search/poblacion/:id'} render={props => <SearchPoblacio {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search/adv'} render={props => <SearchAdv/>} />
                <Route exact path={'*/search/adv/sites'} render={props => <SearchAdvSites/>} />
                <Route exact path={'*'} render={props => <Home/>} />
            </Switch>
        </div>
        <Menu   onMenuClick={() => _stateMenu()}
                    items={[
                        [translate('Inicio'), '/'],

                        [translate('Fondos documentales'), '/docs'],
                        [translate('Entrevistas'), '/interviews'],
                        [translate('Documentos por población'), '/docs/search'],
                        [translate('Mapas'), '/map'],
                        [translate('Exposiciones'), '/expositions'],
                    ]}
            languages={getLanguages()} key={language} langSelected={(lang) => console.log(lang)}
            />
        </>
    );

}
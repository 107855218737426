import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { translate, formatDate, customTrim, locationsName } from '../../assets/js/functions';
import { getFosaByVictim } from '../../assets/js/api';

export default function VictimSearchCard(props) {

    const [victimData, setVictimData]     = useState(null);
    const [victimGeneralData, setVictimGeneralData]     = useState(null);
    const [victimId, setVictimId]     = useState(null);
    const [fosa, setFosa]     = useState(null);

    useEffect(() => {
        setVictimData(props.data);
        setVictimGeneralData(props.victimdata);
        setVictimId(props.victimid);
        getFosaByVictim(props.victimdata.section_id).then(res => setFosa(res));
    }, [])

    return dataReturn();

    function camps(camps) {
        var content = [];
        for (const camp of camps) {
            var info = []

            if (camp.ref_camp) {
                info.push(camp.ref_camp);
            }
            if (camp.data_entrada) {
                info.push(formatDate(camp.data_entrada));
            } else {
                info.push(translate('Sin datos'));
            }
            if (camp.num_matricula) {
                info.push(camp.num_matricula);
            }
            if (info.length > 0) {
                content.push(info.join(' / '));
            }
        }
        return content.map(val => {
            return <>{val}<br></br></>;
        });
    }

    function fields(victimId, victim) {

        if (victimId === 'golpe') {
            return <>
            <h3>
            <svg width="35" height="35"><defs><path id="a" d="M0 0h35v35H0z"/></defs><g fill="none" fillRule="evenodd"><mask id="b" fill="#fff"><use xlinkHref="#a"/></mask><g mask="url(#b)"><g stroke="#000" strokeLinejoin="round" strokeWidth="2"><path d="m20.044 1.34 5.787 2.107-1.053 2.893-5.788-2.106zM23.123 6.658l-3.16 8.681-2.894-1.053 3.16-8.681zM14.545 13.54l7.916 2.88-1.538 4.228a7.597 7.597 0 0 1-6.267 4.948 7.618 7.618 0 0 1-1.64-7.857l1.529-4.2Z"/></g><path fill="#000" stroke="#000" strokeLinejoin="round" strokeWidth="2" d="M7.238 20.265a7.186 7.186 0 0 1 7.158 6.556 5.615 5.615 0 0 1 2.08-.397c1.555 0 2.962.628 3.983 1.644a7.185 7.185 0 1 1 .378 7.585 5.648 5.648 0 0 1-9.81-2.098 7.185 7.185 0 1 1-3.79-13.29Z"/><path fill="#FFF" d="M-2 12.054h6.716l.403 21.478L29 33.187V12.054h8.006v27.715H-2z"/><path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.157 12.054h2.962v21.478H28.78V12.054h3.137"/></g></g></svg>
                {translate('Datos específicos')}
            </h3>
            <ul className="victim-info">
                {(victim.death_typology)?
                <li><span>{translate('Tipo de muerte')}</span> {victim.death_typology}</li>
                :''}
                {/*(victim.death_cause)?
                <li><span>{translate('Causa de la muerte')}</span> {victim.death_cause}</li>
                :''*/}
                {(victim.graveyard)?
                <li><span>{translate('Lugar del asesinato')}</span> {victim.graveyard}</li>
                :''}
                {(fosa)?
                <li><span>{translate('Fosa')}</span> <Link to={'/search/fosa/'+fosa.section_id}>{fosa.title}</Link></li>
                :''}
            </ul>

            </>;
        } else if (victimId === 'exilio') {
            return <>
            <h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28"><path fill="#000" fillRule="nonzero" d="M17.498 2.625a2.625 2.625 0 1 0-5.25.001 2.625 2.625 0 0 0 5.25-.001ZM6.873 9.597a2.901 2.901 0 0 1 2.357-.832L7.524 13.89a3.502 3.502 0 0 0 1.466 4.074l4.713 2.947-1.389 4.856a1.752 1.752 0 1 0 3.369.962l1.57-5.49a2.625 2.625 0 0 0-1.133-2.947l-3.106-1.941 1.69-4.506.279.673a5.258 5.258 0 0 0 4.85 3.231h1.165c.968 0 1.75-.782 1.75-1.75 0-.967-.782-1.75-1.75-1.75h-1.165a1.747 1.747 0 0 1-1.613-1.077l-.345-.82a6.994 6.994 0 0 0-4.401-3.997l-2.663-.82a6.381 6.381 0 0 0-6.403 1.591l-1.27 1.263a1.752 1.752 0 0 0 2.478 2.477l1.263-1.263-.006-.005Zm-1.886 9.651H1.75c-.968 0-1.75.782-1.75 1.75s.782 1.75 1.75 1.75h3.806c1.039 0 1.98-.613 2.4-1.559l.629-1.416-.52-.328a5.216 5.216 0 0 1-2.072-2.455l-1.006 2.258Z"/></svg>
                {translate('Datos específicos')}
            </h3>
            <ul className="victim-info">
                <li><span>{translate('Condición')}</span> {translate('Exiliado')}</li>
            </ul>
            </>;
        } else if (victimId === 'economica') {
            return <>
            <h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"><path fill="#000" fillRule="nonzero" d="M15.625 4.688h-6.25L7.061 1.216A.78.78 0 0 1 7.71 0h9.58a.78.78 0 0 1 .65 1.216l-2.315 3.472ZM9.375 6.25h6.25c.186.122.396.259.635.41C19.028 8.433 25 12.251 25 20.312 25 22.9 22.9 25 20.312 25H4.688A4.689 4.689 0 0 1 0 20.312C0 12.252 5.972 8.432 8.74 6.66c.235-.151.45-.288.635-.41Zm4.106 4.688a.982.982 0 0 0-1.962 0v.292a4.006 4.006 0 0 0-.777.25c-.732.332-1.362.947-1.518 1.84a2.33 2.33 0 0 0 .166 1.416c.205.43.522.733.844.952.567.386 1.314.61 1.885.782l.108.034c.678.205 1.142.361 1.43.571a.515.515 0 0 1 .186.2c.014.04.044.127.01.328-.03.17-.123.312-.391.43-.298.126-.781.19-1.406.092-.293-.049-.816-.225-1.28-.386a5.671 5.671 0 0 0-.307-.102.977.977 0 1 0-.615 1.855l.214.073c.386.132.992.337 1.455.445v.302a.982.982 0 0 0 1.963 0v-.268c.264-.049.513-.122.752-.225.767-.327 1.387-.962 1.543-1.89.088-.507.05-.99-.146-1.435a2.45 2.45 0 0 0-.825-1c-.596-.43-1.382-.67-1.973-.85l-.04-.01c-.693-.21-1.161-.357-1.46-.557-.126-.088-.165-.146-.175-.17-.01-.015-.034-.079-.005-.245.015-.093.093-.254.4-.395.313-.142.801-.22 1.397-.127.21.034.874.16 1.06.21a.98.98 0 0 0 1.196-.694.976.976 0 0 0-.693-1.196 16.518 16.518 0 0 0-1.026-.215v-.307h-.01Z"/></svg>
                {translate('Datos específicos')}
            </h3>
            <ul className="victim-info">
                {(victim.victim_age)?
                <li><span>{translate('Edad en el momento de la sentencia')}</span> {victim.victim_age}</li>
                :''}
                {(victim.judgment_court_martial)?
                <li><span>{translate('Tipo de sentencia')}</span> {victim.judgment_court_martial}</li>
                :''}
                {(victim.economic_sentence)?
                <li><span>{translate('Condena económica')}</span> {victim.economic_sentence}</li>
                :''}
                {(victim.dismissal_date)?
                <li><span>{translate('Fecha sobreseimento')}</span> {formatDate(victim.dismissal_date)}</li>
                :''}
                {(victim.pardon_date)?
                <li><span>{translate('Fecha indulto')}</span> {formatDate(victim.pardon_date)}</li>
                :''}
                {(victim.judgment_council_place)?
                <li><span>{translate('Lugar Consejo de Guerra')}</span> {victim.judgment_council_place}</li>
                :''}
                {(victim.verdict_court_martial)?
                <li><span>{translate('Condena Consejo de Guerra (Tiempo)')}</span> {victim.verdict_court_martial}</li>
                :''}
                {(victim.judgment_council_date)?
                <li><span>{translate('Fecha sentencia Consejo de Guerra')}</span> {formatDate(victim.judgment_council_date)}</li>
                :''}
                {(victim.aga)?
                <li><span>{translate('AGA')}</span> {victim.aga}</li>
                :''}

                {(victim.sentence_disqualification_time)?
                <li><span>{translate('Condena inhabilitación tiempo')}</span> {victim.sentence_disqualification_time}</li>
                :''}

                {(victim.sentence_disqualification)?
                <li><span>{translate('Condena inhabilitación')}</span> {victim.sentence_disqualification}</li>
                :''}

                {(victim.victim_political_affiliation_judgment)?
                <li><span>{translate('Filiación política sentencia')}</span> {customTrim(victim.victim_political_affiliation_judgment)}</li>
                :''}


                {(victim.victim_political_affiliation)?
                <li><span>{translate('Filiación política ficha')}</span> {customTrim(victim.victim_political_affiliation)}</li>
                :''}

                {(victim.sentence_banishment_time)?
                <li><span>{translate('Condena destierro tiempo')}</span> {victim.sentence_banishment_time}</li>
                :''}

                {(victim.sentence_banishment)?
                <li><span>{translate('Condena destierro')}</span> {victim.sentence_banishment}</li>
                :''}

                {(victim.political_office)?
                <li><span>{translate('Cargo político')}</span> {victim.political_office}</li>
                :''}

                {(victim.public_office)?
                <li><span>{translate('Cargo público')}</span> {victim.public_office}</li>
                :''}

            </ul>
            </>;
        } else if (victimId === 'deportacion') {
            return <>
            <h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25"><path fill="#000" fillRule="nonzero" d="M14.844 1.563a1.56 1.56 0 0 1-1.563 1.562 1.56 1.56 0 0 1-1.562-1.563A1.56 1.56 0 0 1 13.28 0a1.56 1.56 0 0 1 1.563 1.563ZM9.375 5.469a1.56 1.56 0 0 1-1.563-1.563 1.56 1.56 0 0 1 1.563-1.562 1.56 1.56 0 0 1 1.563 1.562A1.56 1.56 0 0 1 9.374 5.47Zm-1.563.781a1.56 1.56 0 0 1 1.563 1.563h.39c.65 0 1.172.522 1.172 1.171v.782c0 .083-.01.166-.024.249a7.809 7.809 0 0 1 4.712 7.172A7.81 7.81 0 0 1 7.812 25 7.81 7.81 0 0 1 0 17.187a7.809 7.809 0 0 1 4.712-7.172 1.027 1.027 0 0 1-.024-.25v-.78c0-.65.522-1.172 1.171-1.172h.391A1.56 1.56 0 0 1 7.813 6.25Zm0 15.625a4.689 4.689 0 0 0 0-9.375 4.689 4.689 0 0 0 0 9.375Zm9.375-4.688a9.342 9.342 0 0 0-.703-3.569 7.847 7.847 0 0 1 3.853-3.608 1.027 1.027 0 0 1-.024-.25v-.776c0-.649.522-1.171 1.171-1.171h.391a1.56 1.56 0 0 1 1.563-1.563A1.56 1.56 0 0 1 25 7.813h.39c.65 0 1.172.522 1.172 1.171v.782c0 .083-.01.166-.024.249a7.809 7.809 0 0 1 4.712 7.172A7.81 7.81 0 0 1 23.437 25a7.82 7.82 0 0 1-6.953-4.243 9.324 9.324 0 0 0 .703-3.57Zm10.938 0a4.689 4.689 0 0 0-9.375 0 4.689 4.689 0 0 0 9.375 0ZM17.969 3.126a1.56 1.56 0 0 1-1.563-1.563A1.56 1.56 0 0 1 17.97 0a1.56 1.56 0 0 1 1.562 1.563 1.56 1.56 0 0 1-1.562 1.562Zm3.906 2.344a1.56 1.56 0 0 1-1.563-1.563 1.56 1.56 0 0 1 1.563-1.562 1.56 1.56 0 0 1 1.563 1.562 1.56 1.56 0 0 1-1.563 1.563Z"/></svg>
                {translate('Datos específicos')}
            </h3>
            <ul className="victim-info">
                {(victim.camps_concentracio)?
                <li><span>{translate('Campo de concentración/ Fecha de entrada/ Número de matrícula')}</span><br></br>
                {camps(victim.camps_concentracio)}
                </li>
                :''}
                {(victim.situacio_deportat && (victim.situacio_deportat === 'Muerto/a'))?
                <>
                <li><span>{translate('Situación')}</span> {victim.situacio_deportat}</li>
                {(victimGeneralData.dead_place)?
                <li><span>{translate('Lugar de defunción')}</span> {locationsName(victimGeneralData.dead_place)}</li>
                :''}
                </>
                :''}
                {(victim.situacio_deportat && (victim.situacio_deportat !== 'Muerto/a'))?
                <li><span>{translate('Situación')}</span> {victim.situacio_deportat}</li>
                :''}

            </ul>
            </>;
        }
        return '';
    }

    function dataReturn(){
        return (
            victimData ?
                <>
                    {fields(victimId, victimData)}
                </>
            : <div className="loader1"><div></div><div></div></div>
        )
    }

}
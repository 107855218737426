import React, { useState } from 'react';
import { FaSearch, FaTimesCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { getSearchVictims, getSearchPoblacionsVictimes, getSearchFosas, getSearchPrisiones, getSearchCampos, getSearchBatallones } from '../../assets/js/api';
import { translate, victimNameDisplay, getCurrentLanguage, localeContains } from '../../assets/js/functions';
import './assets/scss/styles.scss';

export default function Searcher(props){

    const [searchInput, setSearchInput]     = useState(null);
    const [dataVictimas, setDataVictimas] = useState([]);
    const [dataPoblacio, setDataPoblacio] = useState([]);
    const [dataFosas, setDataFosas] = useState([]);
    const [dataPrisiones, setDataPrisiones] = useState([]);
    const [dataCampos, setDataCampos] = useState([]);
    const [dataBatallones, setDataBatallones] = useState([]);

    const [dataPrisionesBase, setDataPrisionesBase] = useState(null);
    const [dataCamposBase, setDataCamposBase] = useState(null);
    const [dataBatallonesBase, setDataBatallonesBase] = useState(null);


    function localSearch(text, base) {
        if (!base) {
            return [];
        }
        const textFilter = text.split(' ').filter(res =>
            res.trim().length > 2
        );
        if (textFilter.length === 0) {
            return [];
        }
        return base.filter(value => {
            let result = true;
            textFilter.forEach(text => {
                if (!localeContains(value.term, text)) {
                    result = false;
                }
            });
            return result;
        });
    }

    function _handleInputChange(text){
        if (dataPrisionesBase === null) {
            getSearchPrisiones().then(res => {
                setDataPrisionesBase(res)
            });
        }
        if (dataCamposBase === null) {
            getSearchCampos().then(res => {
                setDataCamposBase(res)
            });
        }
        if (dataBatallonesBase === null) {
            getSearchBatallones().then(res => {
                setDataBatallonesBase(res)
            });
        }


        setSearchInput(text.replace('\'', ''));
        if (text.length >= 3){
            setDataVictimas([]);
            setDataPoblacio([]);
            setDataFosas([]);
            setDataPrisiones([]);
            setDataCampos([]);
            setDataBatallones([]);

            getSearchVictims(text).then(res => {
                setDataVictimas(res.data.result ? res.data.result : null)
            });
            getSearchPoblacionsVictimes(text).then(res => {
                var data = res.data.result ? res.data.result : null;
                data.map(val => {
                    val.location_id = JSON.parse(val.location_id)[0];
                    val.location_municipality = val.location_municipality.split(" - ")[0];
                    return val;
                });

                const key = 'location_id';
                const arrayUniqueByKey = [...new Map(data.map(item => [item[key], item])).values()];

                return setDataPoblacio(arrayUniqueByKey)
            });
            getSearchFosas(text).then(res => {
                setDataFosas(res)
            });

            setDataPrisiones(localSearch(text, dataPrisionesBase));
            setDataCampos(localSearch(text, dataCamposBase));
            setDataBatallones(localSearch(text, dataBatallonesBase));
        }
        if (text.length <3) {
            setDataVictimas([]);
            setDataPoblacio([]);
            setDataFosas([]);
            setDataPrisiones([]);
            setDataCampos([]);
            setDataBatallones([]);
        };
    }

    function _handleInputKeyPress(key){
        if (key === 13) {
            window.location.href = '/'+getCurrentLanguage()+_urlSearch();
            return;
        }
    }

    function _urlSearch(){
        if (isSearchable()) return "/search/victims?query="+searchInput;
        return "#";
    }

    function _clearInput(){
        setSearchInput(null);
        setDataVictimas([]);
        setDataPoblacio([]);
        setDataFosas([]);
        setDataPrisiones([]);
        setDataCampos([]);
        setDataBatallones([]);
}

    function isSearchable(){
        if (searchInput && searchInput.length >= 3) return true;
        return false;
    }


    return (
        <div className={"searcher"}>
            <div className={"input"+(isSearchable() ? " active" : "")}>
                <input onKeyDown={key => _handleInputKeyPress(key.which)} onChange={res => _handleInputChange(res.target.value)} value={searchInput || ''} type="text" placeholder={props.placeholder}/>
                { searchInput ? <div className="clear-input" onClick={() => _clearInput()}><FaTimesCircle/></div> : null }
            </div>
            <Link to={_urlSearch()} className="button"><FaSearch/></Link>

            <div className="searcher-results">

            { dataVictimas && !!dataVictimas.length ?
                <div>
                    <h4>{translate('Víctimas')}</h4>
                    {dataVictimas.map(
                        (res, i) => {
                            return <Link
                                    key={i}
                                    to={'/search/victim/'+res.section_id}>
                                        {victimNameDisplay(res.name, res.surname)}
                                    </Link>
                            }
                        )
                    }
                </div> : null
            }
            { dataPoblacio && !!dataPoblacio.length ?
                <div>
                    <h4>{translate('Poblaciones')}</h4>
                    {dataPoblacio.map(
                        (res, i) => {
                            return <Link
                                    key={i}
                                    to={'/search/poblacion/'+res.location_id+'?loc='+res.location_municipality}>
                                        {res.location_municipality}
                                    </Link>
                            }
                        )
                    }
                </div> : null
            }
            { dataFosas && !!dataFosas.length ?
                <div>
                    <h4>{translate('Fosas')}</h4>
                    {dataFosas.map(
                        (res, i) => {
                            return <Link
                                    key={i}
                                    to={'/search/fosa/'+res.section_id}>
                                        {res.title}
                                    </Link>
                            }
                        )
                    }
                </div> : null
            }
            { dataPrisiones && !!dataPrisiones.length ?
                <div>
                    <h4>{translate('Prisión')}</h4>
                    {dataPrisiones.map(
                        (res, i) => {
                            return <Link
                                    key={i}
                                    to={'/search/adv/sites/prision/'+res.term_id}>
                                        {res.term}
                                    </Link>
                            }
                        )
                    }
                </div> : null
            }
            { dataCampos && !!dataCampos.length ?
                <div>
                    <h4>{translate('Campos de concentración')}</h4>
                    {dataCampos.map(
                        (res, i) => {
                            return <Link
                                    key={i}
                                    to={'/search/adv/sites/campo/'+res.term_id}>
                                        {res.term}
                                    </Link>
                            }
                        )
                    }
                </div> : null
            }
            { dataBatallones && !!dataBatallones.length ?
                <div>
                    <h4>{translate('Batallón de trabajadores')}</h4>
                    {dataBatallones.map(
                        (res, i) => {
                            return <Link
                                    key={i}
                                    to={'/search/adv/sites/batallon/'+res.term_id}>
                                        {res.term}
                                    </Link>
                            }
                        )
                    }
                </div> : null
            }
            </div>

        </div>
    )
}

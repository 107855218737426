import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getVictimsByPoblacio } from '../../assets/js/api';
import { translate, victimNameDisplay } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import './assets/scss/styles.scss';

export default function SearchPoblacion(props){

    const [poblacioId, setPoblacioId]         = useState(null);
    const [poblacio, setPoblacio]         = useState(null);
    const [victimsData, setVictimsData]     = useState(null);
    const loc = decodeURIComponent(window.location.search.split("?loc=")[1]);

    useEffect(() => {
        if (props.match){
            const poblacioId = props.match.params.id;

            if(poblacioId){
                setPoblacioId(poblacioId);
                setPoblacio(loc);

                getVictimsByPoblacio(poblacioId).then(res => {
                    setVictimsData((res.data.result && res.data.result.length > 0) ? res.data.result : null);
                })
            }
        }
    }, []);

    return (
        <div className="content" id="search-poblacion">
            <Breadcrumbs key={poblacioId} currentSite={translate("Buscador")}/>
            <>
                <Helmet>
                    <title></title>
                </Helmet>
                <h2>{translate('Buscador por población')}</h2>

                <Container>
                    {(victimsData !== null)?
                    <div className="result-list">

                        {(poblacio !== null)?
                        <h3>{poblacio}</h3>
                        :''}
                        <h4>{victimsData.length} { translate("resultados")} </h4>
                        <Row className="victims-list">
                        {victimsData.map(function(object, i){
                            return <Col md={6} lg={4}><Link to={'/search/victim/'+object.section_id} key={i} >{victimNameDisplay(object.name, object.surname)}</Link></Col>
                        })}
                        </Row>
                    </div>
                    :<div className="loader1"><div></div><div></div></div>}
                </Container>
            </>
        </div>
    )
}

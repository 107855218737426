import Section from '../layouts/Section/Section';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/scss/base.scss';
import '../assets/scss/constants.scss';
import { useState } from 'react';


const App = () => {

    const [menu, setMenu] = useState(false);

    function _stateMenu(value){
      setMenu(value);
    }

    return (
      <div className="App">
        <div className={"main_content"+(menu ? ' menu-open' : '' )}>
          <Section openMenu={(value) => _stateMenu(value)}/>
        </div>
      </div>
    );

}

export default App;

import React from 'react'
import { translate } from '../../assets/js/functions'
import "./assets/scss/styles.scss"

export default function Selector(props) {
    return (
        <div id='selector'>
            <span>{translate("Filtro")}</span>
            <ul>
                {props.data && props.data.map(
                    res => <li key={res} onClick={() => props.onClick && props.onClick(res)}>{res}</li>
                )}
            </ul>
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap';
import { domain, getFosa } from '../../assets/js/api';
import parse from 'html-react-parser';
import { translate, VictimNameAndLocationDisplay, formatDate, locationsName } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Helmet from 'react-helmet';
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/scss/styles.scss';


export default function Fosa(props){

    const [fosaId, setFosaId]         = useState(null);
    const [fosaData, setFosaData]     = useState(null);
    const [moreInfo, setMoreInfo]     = useState(false);
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        if (props.match){
            const fosaId = props.match.params.id;

            if(fosaId){
                setFosaId(fosaId);
                getFosa(fosaId).then(fosaInfo => {
                    setFosaData(fosaInfo);

                    if (fosaInfo.summary_local_historical_context.slice(0, 400).length >= fosaInfo.summary_local_historical_context.length) {
                        setMoreInfo(true);
                    }
                });
            }
        }
    }, []);

    function moreInfoHandler() {
        setMoreInfo(true);
    }


    return (
            fosaData ?
                <div className="content" id="fosa">
                    <Breadcrumbs key={fosaId} currentSite={translate("Buscador")}/>
                    <>
                        <Helmet>
                            <title>{fosaData.title}</title>
                        </Helmet>
                        <Container>
                        <div className="fosa-card">
                            <Row>
                                <Col md={8}>
                                <h2>{fosaData.title}</h2>
                                <Row>
                                    {(fosaData.municipality)?
                                    <Col md={6}>
                                    <p className="loc">{locationsName(fosaData.municipality)}</p>
                                    </Col>
                                    :''}
                                    {(fosaData.event_date)?
                                    <Col md={6}>
                                    <p className="cal">{parse(fosaData.event_date.split(',').map(value => formatDate(value)).join('<br/>'))}</p>
                                    </Col>
                                    :''}
                                </Row>
                                {fosaData.summary_local_historical_context?
                                <>
                                <h3>
                                    {translate("Contexto")}
                                </h3>
                                {moreInfo?
                                <p>{parse(fosaData.summary_local_historical_context)}</p>
                                :
                                <>
                                <p>{parse(fosaData.summary_local_historical_context.slice(0, 400))}</p>
                                <Link onClick={(event) => {event.preventDefault();moreInfoHandler();}} className="btn-mas" to="">{translate("+ Saber más")}</Link>
                                </>
                                }
                                </>
                                :''}
                                </Col>
                                <Col md={4}>
                                {(fosaData.identify_image_data && fosaData.identify_image_data.length > 0)?
                                <Slider {...settings}>
                                    {fosaData.identify_image_data.map(value =>
                                        <figure>
                                        <img src={domain+value.image} alt=""></img>
                                        {value.description?
                                        <figcaption>{value.description}</figcaption>
                                        :''}
                                        </figure>
                                    )}
                                </Slider>

                                :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                <h3>
                                    {translate("Exhumaciones")}
                                </h3>
                                    {(fosaData.general_state)?
                                    <>
                                    <h4>{translate("Estado")}</h4>
                                    <p>{fosaData.general_state}</p>
                                    </>
                                    :''}
                                    {fosaData.ref_interventions_data?
                                    <>
                                    <Tabs className="fosa-tabs" forceRenderTabPanel defaultIndex={0}>
                                        <TabList>
                                        {fosaData.ref_interventions_data.map((intervention, index) => {
                                        return <>
                                            <Tab key={index}><span>{translate('Actuación')} {index+1}</span></Tab>
                                        </>
                                        })}
                                        </TabList>
                                        {fosaData.ref_interventions_data.map((intervention, index) => {
                                        return <TabPanel key={index}>
                                            {(intervention.type)?
                                            <>
                                            <h4>{translate("Período")}</h4>
                                            <p>{intervention.type}</p>
                                            </>
                                            :''}
                                            {(intervention.interventions_comments)?
                                            <>
                                            <h4>{translate("Contexto")}</h4>
                                            <p>{parse(intervention.interventions_comments)}</p>
                                            </>
                                            :''}
                                            {(intervention.archeological_memoria_pdf)?
                                            <>
                                            <a className="btn-mas" target="_blank" href={domain+intervention.archeological_memoria_pdf}>{translate("Documento")}</a>
                                            </>
                                            :''}
                                        </TabPanel>
                                        })}
                                    </Tabs>
                                    </>
                                    :''}

                                <Link className="btn-map" to="/maps/fosas">{translate("Ver mapa")}</Link>
                                </Col>
                                <Col md={6}>
                                    {fosaData.documented_deceased && fosaData.documented_deceased.length > 0?
                                    <>
                                    <h3>
                                        {translate("Víctimas")}
                                    </h3>
                                    <ul className="victim-list">
                                        {fosaData.documented_deceased.map(value =>
                                            <li><Link to={'/search/victim/'+value.section_id}>{parse(VictimNameAndLocationDisplay(value))}</Link></li>
                                        )}
                                    </ul>
                                    </>
                                    :''}
                                </Col>
                            </Row>


                        </div>
                        </Container>
                    </>
                </div>
            : <div className="loader1"><div></div><div></div></div>
    )
}

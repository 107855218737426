import React from 'react';
/*import parse, { attributesToProps } from 'html-react-parser';*/
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCurrentLanguage } from '../../assets/js/functions';
import './assets/scss/styles.scss';


export default function LearnMore(){

/*
    function parsedText(html){
        const options = {
            replace: domNode => {
              if (domNode.attribs && domNode.name === 'mark') {
                // const props = attributesToProps(domNode.attribs);
                return <Link target={"_blank"} className="mark" to={"#"}><mark>{ domNode.children[0].data }</mark></Link>
              }
            }
          };
        return parse(html, options);
    }
*/

    function infoText(lang) {
        if (lang === 'es') {
            return <span className='text'>
            <p><strong>Oroibidea</strong>, camino de memoria, sugiere que hay múltiples sendas que transitar en la recuperación de la memoria en Navarra. Caminos diversos, como la memoria oral conservada por las familias; los documentos públicos y privados que hablan tanto de las víctimas como de los movimientos de recuperación de su memoria; imágenes fotográficas, sobre todo del período de las exhumaciones tempranas; materiales de investigación y bases de datos elaboradas durante años, como la del Fondo Documental de la Memoria Histórica de Navarra (FDMHN).</p>
            <p>Todos esos materiales se entrelazan en Oroibidea en torno a un núcleo fundamental: las víctimas de las diferentes modalidades de violencia y represión. Esas víctimas pueden ser localizadas a través del <strong>buscador </strong>de la página principal, pero también utilizando la <strong>Búsqueda avanzada</strong>, que permite refinar esa búsqueda por <strong>víctimas</strong> o por <strong>lugares de represión</strong>.</p>
            <p>En la <strong>ficha</strong> de cada persona puede accederse a la información disponible sobre ella: los datos personales principales, la modalidad o modalidades represivas sufridas, y los documentos, imágenes, fotografías, fragmentos de entrevistas, referencias bibliográficas y ficha del FDMHN referidas a esa persona.</p>
            <p>Es posible acceder a los fondos documentales y a las colecciones de entrevistas utilizadas en el proyecto. Pulsando en <strong>Fondos documentales</strong> se pueden consultar los fondos procedentes de archivos públicos y privados que componen el archivo digital del Instituto Navarro de la Memoria, con los documentos, fotografías, imágenes y audiovisuales utilizados.</p>
            <p>En <strong>Entrevistas</strong> se accede al archivo de la memoria oral sobre el pasado traumático de Navarra. Ahí se encuentran las 365 entrevistas, depositadas en el Instituto Navarro de la Memoria, que han permitido indizar 4.880 fragmentos mediante la navegación por las palabras clave que los describen en el apartado <Link to="/interviews/explore">explora</Link>. Las entrevistas han sido transcritas y pueden buscarse todas las palabras recogidas en los testimonios en el buscador de esta sección, que también sugiere las palabras clave con las que se han indizado los fragmentos de entrevistas.</p>
            <p>El botón <strong>Documentos por población</strong> permite acceder a la documentación referida a la población de Navarra que deseemos buscar, pudiendo filtrar los contenidos por tipo de fondo o de documento.</p>
            <p>En <strong>Mapas</strong> podemos acceder a desarrollos cartográficos que muestran información concreta sobre víctimas o sobre lugares de represión. Dentro de cada mapa se puede acceder a las víctimas relacionadas y a sus respectivas fichas.</p>
            <p>Por último, <strong>Exposiciones</strong> da paso a exposiciones virtuales que desarrollan temáticas específicas, como la cautividad o las exhumaciones tempranas.</p>
            <p>El archivo es cuatrilingüe castellano-euskera-inglés-francés.</p>
            <p>Para más información sobre el proyecto, pueden ponerse en contacto a través del correo electrónico: <a href="mailto:inm@navarra.es">inm@navarra.es</a></p>
            </span>;
        } else if (lang === 'eu') {
            return <span className='text'>
                <p><strong>Oroibideak</strong> Nafarroan oroimena berreskuratzeko bide asko daudela iradokitzen du. Hainbat bide, hala nola familiek kontserbatutako ahozko memoria; biktimei buruz zein haien memoria berreskuratzeko mugimenduei buruz hitz egiten duten dokumentu publiko eta pribatuak; argazki-irudiak, batez ere lurpetik goiz atera zireneko aldikoak; urteetan landutako ikerketa-materialak eta datu-baseak, hala nola Nafarroako Oroimen Historikoaren Dokumentazio Funtsarena (NOHDF).</p>
                <p>Material horiek guztiak funtsezko nukleo baten inguruan elkartzen dira Oroibidean: indarkeria eta errepresio mota desberdinen biktimak. Biktima horiek orri nagusiko <strong>bilatzailearen</strong> bidez aurki daitezke, baina baita <strong>Bilaketa aurreratua</strong> erabiliz ere, bilaketa hori <strong>biktimen</strong> edo <strong>errepresio-tokien</strong> bidez fintzeko aukera ematen baitu.</p>
                <p>Pertsona bakoitzaren <strong>fitxan</strong>, hari buruz eskuragarri dagoen informazioa eskura daiteke: datu pertsonal nagusiak, jasandako errepresio-modalitatea edo -modalitateak, eta pertsona horri buruzko dokumentuak, irudiak, argazkiak, elkarrizketa-zatiak, erreferentzia bibliografikoak eta NOHDFren fitxa.</p>
                <p>Halaber, ikus daitezke proiektuan erabilitako dokumentu-funtsak eta elkarrizketa-bildumak. Dokumentu-funtsak botoia sakatuz gero, Nafarroako Memoriaren Institutuaren artxibo digitala osatzen duten artxibo publiko eta pribatuetako funtsak eskura daitezke. Bertan, erabili diren dokumentuak, argazkiak, irudiak eta ikus-entzunezkoak aurki daitezke.</p>
                <p><strong>Elkarrizketak</strong> botoian, Nafarroako iragan traumatikoari buruzko ahozko memoriaren artxibora sartzen da. Hor daude 365 elkarrizketa, Nafarroako Memoriaren Institutuan gordetakoak, 4.880 zati adierazteko aukera eman dutenak, <Link to="/interviews/explore">arakatze-atalean</Link> deskribatzen dituzten gako-hitzen nabigazioaren bidez berreskura daitezkeenak. Elkarrizketak transkribatu dira, eta bilaketaren erdiko tiraderan testigantzek esandako hitz guztiak bila daitezke. Kaxoi horrek elkarrizketa-zatiak indexatzeko erabili diren gako-hitzak ere iradokitzen ditu.</p>
                <p><strong>Dokumentuak herriaren arabera</strong> botoiaren bidez, bilatu nahi dugun Nafarroako biztanleriari buruzko dokumentazioa eskura daiteke, eta edukiak funts edo dokumentu motaren arabera iragaz daitezke.</p>
                <p><strong>Mapetan</strong> biktimei edo errepresio-tokiei buruzko informazio zehatza erakusten duten garapen kartografikoak ikus ditzakegu. Mapa bakoitzaren barruan, zerrendatutako biktimei buruzko erreferentziak, eta, horiei esker, dagozkien fitxak eskura ditzakegu.</p>
                <p>Azkenik, <strong>Erakusketek</strong> gai espezifikoak garatzen dituzten erakusketa birtualetara sartzeko aukera ematen dute, hala nola gatibutasuna edo hobitik goiz ateratzea.</p>
                <p>Artxiboa hau lau hizkuntzatan dago: gaztelania-euskara-ingelesa-frantseza.</p>
                <p>Proiektuari buruzko informazio gehiago nahi izanez gero, jarri harremanetan posta elektronikoaren bidez: <a href="mailto:inm@navarra.es">inm@navarra.es</a></p>
            </span>;
        } else if (lang === 'en') {
            return <span className='text'>
                <p><strong>Oroibidea</strong>, “memory road”, suggests that there are multiple paths to travel in the recovery of memory in Navarra. Diverse paths, such as oral memory preserved by families; the public and private documents that speak of both the victims and the movements of recovery of their memory; photographic images, especially of the period of early exhumations; research materials and databases prepared for years, such as the Documentary Fund for the Historical Memory of Navarra (FDMHN).</p>
                <p>All these materials are intertwined in Oroibidea around a fundamental core: the victims of the different modalities of violence and repression. These victims can be located through the main page’s <strong>search engine</strong>, but also using the <strong>Advanced search</strong>, which allows to refine that search by <strong>victims</strong> or by <strong>places of repression</strong>.</p>
                <p>In each person's <strong>file</strong>, information available on it can be accessed: the main personal data, the repressive modality or modalities suffered, and the documents, images, photographs, interview fragments, bibliographic references and the FDMHN file referred to that person.</p>
                <p>It is possible to access the documentary and interview collections used in the project. By clicking on <strong>Documentary collections</strong>, you can consult the collections from public and private archives that make up the digital archive of the Navarre Institute of Memory, with the used documents, photographs, images and audiovisuals.</p>
                <p>In <strong>Interviews</strong> you can access the archive of oral memory on the traumatic past of Navarra. There are 365 interviews, deposited at the Navarre Institute of Memory, which have allowed to index 4,880 fragments by navigating through the keywords that describe them in the <Link to="/interviews/explore">Explore</Link> section. The interviews have been transcribed and all the words collected in the testimonies in the search engine of this section can be sought, which also suggests the keywords with which the interview fragments have been indexed.</p>
                <p>The <strong>Documents by town/village</strong> button allows access to the documentation referring to the population of Navarra that we want to search, being able to filter the contents by type of collection or document.</p>
                <p>In <strong>Maps</strong> we can access cartographic developments that show specific information about victims or places of repression. Within each map you can access related victims and their respective files.</p>
                <p>Finally, <strong>Exhibitions</strong> gives way to virtual exhibitions that develop specific themes, such as captivity or early exhumations.</p>
                <p>The archive is in four languages: Spanish, Basque, English and French. For more information about the project, you can contact the email: <a href="mailto:inm@navarra.es">inm@navarra.es</a></p>
            </span>;
        } else if (lang === 'fr') {
            return <span className='text'>
                <p><strong>Oroibidea</strong>, une route de mémoire, suggère qu'il existe plusieurs chemins pour voyager dans la récupération de la mémoire à Navarra. Divers voies, telles que la mémoire orale conservée par les familles; les documents publics et privés qui parlent des victimes et des mouvements de récupération de leur mémoire; Images photographiques, en particulier la période des premières exhumations; Matériel de recherche et bases de données préparées pendant des années, telles que le Fonds documentaire pour la mémoire historique de Navarra (FDMHN).</p>
                <p>Tous ces matériaux sont liés à Oroibidea autour d'un noyau fondamental: les victimes des différentes modalités de violence et de répression. Ces victimes peuvent être situées via le <strong>moteur de recherche</strong> de pages principal, mais également en utilisant la <strong>recherche avancée</strong>, qui permet d'affiner cette recherche de <strong>victimes</strong> ou par des <strong>lieux de répression</strong>.</p>
                <p>Dans le <strong>fichier</strong> de chaque personne, les informations disponibles sur l'informatique sont accessibles: les principales données personnelles, la modalité ou les modalités répressives subis, et les documents, les images, les photographies, les fragments d'entrevue, les références bibliographiques et le fichier FDMHN référé à cette personne.</p>
                <p>Il est possible d'accéder aux fonds documentaires et aux collections d'interview utilisées dans le projet. En cliquant sur les <strong>fonds documentaires</strong>, vous pouvez consulter les fonds des archives publiques et privées qui composent les archives numériques de l'Institut Navarro de la Memoria, avec les documents, les photographies, les images et les audiovisuels utilisés.</p>
                <p>Dans les <strong>entretiens</strong>, vous pouvez accéder aux archives de la mémoire orale sur le passé traumatisant de Navarra. Il y a les 365 entretiens, déposés au Navarro de la Memoria Institute, qui ont permis d'indiquer 4.880 fragments en naviguant sur les mots clés qui les décrivent dans la section <Link to="/interviews/explore">Explorez</Link>. Les entretiens ont été transcrits et tous les mots collectés dans les témoignages du moteur de recherche de cette section peuvent être recherchés, ce qui suggère également les mots clés avec lesquels les fragments d'entrevue ont été indisgents.</p>
                <p>Le bouton <strong>Documents by Population</strong> permet d'accéder à la documentation se référant à la population de Navarra que nous voulons rechercher, en mesure de filtrer le contenu par type de fonds ou de document.</p>
                <p>Dans les <strong>cartes</strong>, nous pouvons accéder aux développements cartographiques qui montrent des informations concrètes sur les victimes ou sur des lieux de répression. Dans chaque carte, vous pouvez accéder aux victimes connexes et à leurs puces respectives.</p>
                <p>Enfin, les <strong>expositions</strong> cèdent la place à des expositions virtuelles qui développent des thèmes spécifiques, tels que la captivité ou les exhumations précoces.</p>
                <p>Le fichier se trouve en quatre langues: espagnol, basque, anglais et français.</p>
                <p>Pour plus d'informations sur le projet, vous pouvez contacter l'e-mail: <a href="mailto:inm@navarra.es">inm@navarra.es</a></p>
            </span>;
        }
    }

    return (
        <div id="learn-more">
            <Container>
                {infoText(getCurrentLanguage())}
            </Container>
        </div>
    )

}

import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import { Col, Row } from 'react-bootstrap'
import Modal from "react-responsive-modal";
import parse from 'html-react-parser';
import './assets/scss/styles.scss';
import { translate } from '../../assets/js/functions';
import { domain, getVictimInterviews, getVictimInterviewsIndexLocator } from '../../assets/js/api';

export default function VictimInterviews(props) {

    const [victimId, setVictimId]     = useState(null);
    const [data, setData]     = useState(null);
    const [fragments, setFragments]     = useState(null);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        setVictimId(props.victimid);
        getVictimInterviews(props.victimid).then(res => {
          setData(res);
          if (res.length > 0) {
            const promise = res.map(i => {
              return getVictimInterviewsIndexLocator(i).then(interview => {
                  return interview.data.result;
              })
            });
            Promise.all(promise).then(response => {
                setFragments(response);
            })
          }
        });
    }, [])

    const closeIcon = (
      <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    return dataReturn();

    function dataReturn(){
        return (
          data && data.length > 0 ?
            <li>
            <button onClick={() => setOpen(o => !o)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><g fill="none" fillRule="evenodd"><path fill="#A3233B" d="M2.356 15.034a2.345 2.345 0 0 0 1.98 4.255l5.313-2.476v7.05c0 .65-.532 1.182-1.182 1.182h-3.25a1.478 1.478 0 0 0 0 2.955h11.818a1.478 1.478 0 0 0 0-2.955h-3.25c-.65 0-1.182-.532-1.182-1.182v-8.427l1.998-.933a6.437 6.437 0 0 0 7.014 1.093l3.877-1.767c3.167-1.448 4.539-5.141 3.061-8.25-1.477-3.102-5.242-4.444-8.415-2.996L16.261 4.35c-2.393 1.099-3.764 3.469-3.652 5.904l-10.253 4.78ZM22.059 6.43a1.478 1.478 0 1 1 2.955.001 1.478 1.478 0 0 1-2.955 0Z"/><path d="M0 0h30v30H0z"/></g></svg>
            {translate('Fragmentos de entrevista')}
            </button>
            <Modal open={open}
                onClose={closeModal}
                closeIcon={closeIcon}>
              <div className="modal-popup">
                <div className="header">
                {translate('Fragmentos de entrevista')}
                </div>
                <div className="content">
                      {fragments && fragments.map(
                          (res, i) => {
                            return <div className='victim-ref-list'>
                              <Row>
                                <Col md={6}>
                                  <video controls>
                                    <source src={ domain + res.video_url} type="video/mp4"/>
                                  </video>
                                </Col>
                                <Col md={6}>
                                  {(res.fragm)?
                                  <p>{parse(res.fragm)}</p>
                                  :''}
                                </Col>
                              </Row>
                            </div>
                          })}
                </div>
              </div>
            </Modal>
          </li>
          :''
        );
    }

}
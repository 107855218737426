import React, { useEffect } from 'react';
import './assets/scss/styles.scss';
import { Container } from 'react-bootstrap';
import { victimNameDisplay, getCurrentLanguage, groupBy, leafletIcon } from '../../assets/js/functions';
import { getNaziMaps, getVictimasNaziMapsMarks } from '../../assets/js/api';

export default function MapNazi(props){

    var map = null;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.js";
        script.async = true;
        script.onload = () => scriptLoaded();
        document.body.appendChild(script);

        const style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
        document.head.appendChild(style);
    }, [])

    function scriptLoaded(){
        var L = window.L;
        map = new L.map(document.getElementById('mapa'));
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
        const icon = leafletIcon();
        // Target's GPS coordinates.
        var target = L.latLng('48.2699', '11.4683');
        map.setView(target, 5);
        getNaziMaps().then((res) => {

            if (res.data.result === null || res.data.result.length == 0) {
                return;
            }

            for (const campo of res.data.result) {
                if (!campo.space_geojson) {
                    continue;
                }
                const title = campo.term;
                const location = JSON.parse(campo.space_geojson)[0].layer_data.features[0].geometry.coordinates;
                const target = L.latLng(location[1], location[0]);

                getVictimasNaziMapsMarks(campo.term_id).then((res) => {
                    var popup = L.popup();
                    var popupContent = '';

                    if (res.data.result === null || res.data.result.length == 0) {
                        return;
                    }

                    const data = res.data.result.filter(function(value) {
                        return value.ref_camps_concentracio_camp_geojson !== undefined && value.ref_camps_concentracio_camp_geojson !== null;
                    });

                    const dataMap = data.map(value => {
                        const location = JSON.parse(value.ref_camps_concentracio_camp_geojson)[0].layer_data.features[0].geometry.coordinates;
                        value.location = location;
                        //value.victim = JSON.parse(value.victim);
                        return value;
                    });

                    var arr = groupBy(dataMap, 'location');

                    arr = (Object.values(arr))
                    arr.map(
                        res => {
                            res.map(
                                val => {
                                    popupContent += "<li><a href=/"+getCurrentLanguage()+"/search/victim/"+JSON.parse(val.deportat)[0]+"/deportacion target='_blank'>"+victimNameDisplay(val.ref_nom, val.ref_cognom)+"</a></li>"
                                }
                            )
                        }
                    )

                    L.marker(target, {icon: icon})
                    .addTo(map)
                    .bindPopup(popup);
                    popup.setContent('<h6>'+title+'</h6><div class="marker-data-list"><ul class="popupMap">'+popupContent+'</ul></div>');
                })


            };
        });
    }


    return (
        <Container>
            <div id="mapa"></div>
        </Container>
    )
}

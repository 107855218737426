import React, { useEffect } from 'react';
import './assets/scss/styles.scss';
import { Container } from 'react-bootstrap';
import { victimNameDisplay, getCurrentLanguage, groupBy, translate } from '../../assets/js/functions';
import { getVictimasMapsMarks } from '../../assets/js/api';
import proj4 from 'proj4';

export default function MapVictimas(props){

    var map = null;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sitna.tracasa.es/api/";
        script.async = true;
        script.onload = () => scriptLoaded();

        document.body.appendChild(script);
    }, [])

    function scriptLoaded(){
        var SITNA = window.SITNA;

        proj4.defs('EPSG:25830', "+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs");
        proj4.defs('EPSG:4326', "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");

        map = new SITNA.Map("mapa", {
            baseLayers: [
                SITNA.Consts.layer.IDENA_DYNBASEMAP
            ],
            defaultBaseLayer: SITNA.Consts.layer.IDENA_DYNBASEMAP,
            controls: {
                overviewMap: {
                    layer: SITNA.Consts.layer.IDENA_DYNBASEMAP
                }
            }
        });

        getVictimasMapsMarks(props.match.params.id).then((res) => {

            const data = res.data.result.map(value => {
                var location = null;
                if (value.ref_victim_residence_geojson !== undefined && value.ref_victim_residence_geojson !== null) {
                    location = JSON.parse(value.ref_victim_residence_geojson)[0].layer_data.features[0].geometry.coordinates;
                } else if (value.deportat !== undefined && value.deportat[0].location_geojson !== undefined && value.deportat[0].location_geojson !== null) {
                    location = JSON.parse(value.deportat[0].location_geojson)[0].layer_data.features[0].geometry.coordinates;
                }
                value.location = location;

                if (value.ref_victim_name === undefined) {
                    value.ref_victim_name = value.ref_nom;
                }
                if (value.ref_victim_surname === undefined) {
                    value.ref_victim_surname = value.ref_cognom;
                }
                if (value.victim === undefined) {
                    value.victim = value.deportat[0].section_id;
                } else {
                    value.victim = JSON.parse(value.victim)[0];
                }
                return value;
            });

            const dataMap = data.filter(function(value) {
                return value.location !== undefined && value.location !== null;
            });

            var arr = groupBy(dataMap, 'location');

            map.loaded(function () {
                var arrMarks = [];
                let currentData = null;
                let locations = []
                arr = (Object.values(arr))
                arr.map(
                    res => {
                        res.map(
                            val => {
                                currentData = "<div class='marker-data'>"+
                                                    "<div class='title'>"+
                                                        "<h2>"+victimNameDisplay(val.ref_victim_name, val.ref_victim_surname)+"</h2>"+
                                                    "</div>"+
                                                    "<div class='buttons'>"+
                                                        "<a href=/"+getCurrentLanguage()+"/search/victim/"+val.victim+"/"+props.match.params.id+" target='_blank'>"+translate("Ver datos")+"</a>"+
                                                        (val.information_sources_uri ? "<a href="+JSON.parse(val.information_sources_uri)[0].iri+" target='_blank'>"+translate("Ver ficha")+"</a>" : "")+
                                                    "</div>"+
                                                "</div>"
                                    arrMarks.push(currentData);
                                    locations = val.location;
                            }
                        )
                        var locUTM = proj4('EPSG:4326', 'EPSG:25830', locations);
                        map.addMarker(locUTM, {
                            data: arrMarks,
                            width: 22,
                            cssClass: "tc-marker2"
                        })
                        arrMarks = [];
                        locations = [];
                    }
                )
            })
        })
    }


    return (
        <Container>
            <div id="mapa"></div>
        </Container>
    )
}

import React, { useState, useEffect } from 'react';
import './assets/scss/styles.scss';
import { Container } from 'react-bootstrap';
import { cleanArrayUndefined, getCurrentLanguage, groupBy, translate } from '../../assets/js/functions';
import { getTestimoniosMapsMarks } from '../../assets/js/api';

export default function MapTestimonios(props){

    var map = null;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sitna.tracasa.es/api/";
        script.async = true;
        script.onload = () => scriptLoaded();

        document.body.appendChild(script);
    }, [])

    function scriptLoaded(){
        var SITNA = window.SITNA;
        map = new SITNA.Map("mapa", {
            crs: "EPSG:4326",
            initialExtent: [
                -2.84820556640625,
                41.78912492257675,
                -0.32135009765625,
                43.55789822064767
            ],
            /*maxExtent: [
                -2.84820556640625,
                41.78912492257675,
                -0.32135009765625,
                43.55789822064767
            ],*/
            baselayerExtent: [
                -2.84820556640625,
                41.78912492257675,
                -0.32135009765625,
                43.55789822064767
            ],
            baseLayers: [
                SITNA.Consts.layer.IDENA_DYNBASEMAP
            ],
            defaultBaseLayer: SITNA.Consts.layer.IDENA_DYNBASEMAP,
            controls: {
                overviewMap: {
                    layer: SITNA.Consts.layer.IDENA_DYNBASEMAP
                }
            }
        });

        getTestimoniosMapsMarks().then((res) => {
                const dataMap = res.data.result.filter(
                    mark =>{
                        try {
                            return (
                                (mark.space != null && JSON.parse(mark.space) &&
                                JSON.parse(mark.space).lon) ||
                                (mark.location_map != null && JSON.parse(mark.location_map) &&
                                JSON.parse(mark.location_map).lon)
                            );
                        } catch (e) {
                            return false;
                        }
                    }
                )
                var arr = groupBy(cleanArrayUndefined(dataMap), 'location');
                map.loaded(function () {
                    var arrMarks = [];
                    let currentData = null;
                    let locations = []
                    arr = (Object.values(arr))
                    arr.map(
                        res => {
                            res.map(
                                val => {
                                    currentData = "<div class='marker-data'>"+
                                                        "<div class='title'>"+
                                                            "<h2>"+val.term+"</h2>"+
                                                        "</div>"+
                                                        "<div class='buttons'>"+
                                                            "<a href=/"+getCurrentLanguage()+"/interviews/explore/"+val.term_id+" target='_blank'>"+translate("Ver entrevista")+"</a>"+
                                                            (val.external_uri ? "<a href="+JSON.parse(val.external_uri)[0].iri+" target='_blank'>"+translate("Ver ficha")+"</a>" : "")+
                                                        "</div>"+
                                                    "</div>"
                                        arrMarks.push(currentData);
                                        locations = JSON.parse(val.location_map) ? [JSON.parse(val.location_map).lon, JSON.parse(val.location_map).lat] : [JSON.parse(val.space).lon, JSON.parse(val.space).lat];
                                }
                            )
                            map.addMarker(locations, {
                                data: arrMarks,
                                width: 22,
                                cssClass: "tc-marker2"
                            })
                            arrMarks = [];
                            locations = [];
                        }
                    )
                })
            }
        )
    }


    return (
        <Container>
            <div style={{height: "60vh"}}>
                <div id="mapa"></div>
            </div>
        </Container>
    )
}

import Modal from "react-modal/lib/components/Modal";
import { domain, subtitlesUrl } from "../../assets/js/api";
import React from 'react';
import './assets/scss/styles.scss'
import { FaTimesCircle } from "react-icons/fa";
import {getCurrentLanguage} from '../../assets/js/functions'

export default function VideoModal(props) {

    function _onHandleClose(){
        props.onClose && props.onClose(!props.modal);
    }

    return (
        <Modal  isOpen={props.modal}
                onRequestClose={() => _onHandleClose()}
                ariaHideApp={false}
                contentLabel="videoModal">
                    {props.audiovisual ?
                        <div className="modal-video">
                            <video controls crossOrigin="anonymous">
                                <source src={ domain + props.audiovisual} type="video/mp4"/>
                                { props.subtitles && <track default kind="subtitles" src={ subtitlesUrl(props.subtitles)} srcLang={getCurrentLanguage()}/> }
                                Your browser does not support the video tag.
                            </video>
                            <span className="modal-close" onClick={() => _onHandleClose()}>
                                <FaTimesCircle/>
                            </span>
                        </div>
                    : <div className="loader1"><div></div><div></div></div>}
        </Modal>
    )
}

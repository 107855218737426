import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { getTypeByTermId } from '../../assets/js/functions'
import './assets/scss/styles.scss'

export default function CombineItems(props) {

    return (
        props.items ?
            <div id='filter-items' className='d-flex'>
                { props.items.map(
                    res =>  itemType(res)
                ) }
            </div>
        : null
    )

    function itemType(res){
        const type = getTypeByTermId(res[0]);
        return <span className={'filter-item btn '+(type)} key={res[0]}>
                    <span className='label'>{res[1]}</span>
                    <FaTimesCircle className='remove' onClick={() => props.onRemove ? props.onRemove(res) : null}/>
                </span>
    }
}

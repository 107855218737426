import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { getParents } from '../../assets/js/api';
import { translate } from '../../assets/js/functions';
import './assets/scss/styles.scss';

export default function Breadcrumbs (props){

    const [data, setData] = useState(null);

    useEffect(() => {
        if(props.currentId) getParents(props.currentId).then(res => setData(res.data.result.reverse()));
    }, [props.currentId]);

    return (
        <div id="breadcrumbs">
            <Container>
                <Link to={"/"} className="item">{translate("Inicio")}</Link>
                {
                    props.beforeData && props.beforeData.map(res => <Link key={res.name} className="item" to={res.url}>{res.name}</Link>)
                }
                {
                    props.staticData ? props.staticData.map(res => <Link key={res.name} className="item" to={res.url}>{res.name}</Link>) :
                    (data && data.map(res => <Link key={res.term_id} className="item" to={res.term_id}>{res.term}</Link>))
                }
                {
                    props.currentSite && <span key={props.currentSite} className="item current-item">{props.currentSite}</span>
                }
            </Container>
        </div>
    )
}


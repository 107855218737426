import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { BsMenuButtonWide } from 'react-icons/bs';
import parse from 'html-react-parser';
import { getDocsCatalog, getDocs } from '../../assets/js/api';
import { translate } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Doc from '../../components/Docs/Doc';
import Helmet from 'react-helmet';
import './assets/scss/styles.scss';


export default function Docs(props){

    const [docsCatalogId, setDocsCatalogId]         = useState(null);
    const [docsCatalogData, setDocsCatalogData]     = useState(null);
    const [data, setData]                   = useState(null);
    const breadcrumbsStatic                 = [
        {name: translate("Fondos documentales"), url: "/docs"}
    ];

    useEffect(() => {
        if (props.match){
            const docsCatalogId = props.match.params.id;
            if(docsCatalogId){
                setDocsCatalogId(docsCatalogId);
                getDocsCatalog(docsCatalogId).then(res => { setDocsCatalogData(res.data.result ? res.data.result[0] : null) });
                getDocs(docsCatalogId).then(res => setData(res.data.result ? res.data.result : null));
            }
        }
    }, []);

    return (
            data && docsCatalogData ?
                <div className="content" id="docs-list">
                    <Breadcrumbs key={docsCatalogId} staticData={breadcrumbsStatic} currentSite={docsCatalogData.collection_archive}/>
                    <>
                        <h2>{docsCatalogData.collection_archive}</h2>
                        <Helmet>
                            <title>{docsCatalogData.collection_archive}</title>
                        </Helmet>
                        <Container>
                            {(docsCatalogData.description) ? <div className='description'>
                                {parse(docsCatalogData.description)}
                            </div> : ''}

                            <div className="result-list">
                                <h4 className="title">{data.length} {translate('resultados')}</h4>
                                <Row className="docs-list">
                                    <Col lg={12} className="docs-cards">
                                        <Row>
                                        {
                                            data.map(
                                                (res, i) => <Col md={3} className="docs-card"><Doc key={i} data={res} hideDesc={docsCatalogId == 14}/></Col>
                                            )
                                        }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </>
                </div>
            : <div className="loader1"><div></div><div></div></div>
    )
}

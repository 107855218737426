import React, { useEffect, useState } from 'react'
import { domain } from '../../assets/js/api';
import './assets/scss/styles.scss';
import PhotoModal from '../Modals/PhotoModal';
import parse from 'html-react-parser';

export default function Doc(props) {
    const [docData, setDocData]     = useState(null);
    const [hideDesc, setHideDesc]     = useState(null);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        setDocData(props.data);
        setHideDesc(props.hideDesc);
    }, [])

    return dataReturn();


    function dataReturn(){
        return (
            docData ?
            <>
            <div className="docs-body">
                {(docData.identify_image)?
                <a className="img" href={domain+docData.identify_image} alt="" onClick={(event) => {event.preventDefault(); setModal(!modal)}}><img src={domain+docData.identify_image} /></a>
                :''}
                {(!hideDesc && docData.identify_image && docData.identify_image_caption)?
                <p>
                {docData.identify_image_caption}
                </p>
                :''}
                {(docData.audiovisual_data.length > 0)?
                <video controls>
                    <source src={domain+docData.audiovisual} type="video/mp4"></source>
                </video>
                :''}
                {(docData.audiovisual_data.length > 0 && docData.audiovisual_data[0].rsc36)?
                <p>
                {parse(docData.audiovisual_data[0].rsc36)}
                </p>
                :''}
            </div>
            {(docData.identify_image)?
            <PhotoModal modal={modal} photo={domain+docData.identify_image} onClose={(val) => setModal(val)}/>
            :''}
            </>
        : <div className="loader1"><div></div><div></div></div>
        )
    }

}
import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import { Col, Row } from 'react-bootstrap'
import Modal from "react-responsive-modal";
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { translate } from '../../assets/js/functions';
import { domain, getVictimDocs } from '../../assets/js/api';

export default function VictimDocs(props) {

    const [victimId, setVictimId]     = useState(null);
    const [data, setData]     = useState(null);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        setVictimId(props.victimid);
        getVictimDocs(props.victimid).then(res => {
          setData(res.data.result ? res.data.result : null) }
      );
    }, [])

    const closeIcon = (
      <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    return dataReturn();

    function dataReturn(){
        return (
          data && data.length > 0 ?
            <li>
            <button onClick={() => setOpen(o => !o)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><g fill="none" fillRule="evenodd"><path fill="#A3233B" fillRule="nonzero" d="M7.667 0C5.644 0 4 1.626 4 3.625v21.75C4 27.375 5.644 29 7.667 29h14.666C24.356 29 26 27.374 26 25.375V9.062h-7.333a1.821 1.821 0 0 1-1.834-1.812V0H7.667Zm11 0v7.25H26L18.667 0Zm-8.25 14.5h9.166c.505 0 .917.408.917.906a.914.914 0 0 1-.917.906h-9.166a.914.914 0 0 1-.917-.906c0-.498.412-.906.917-.906Zm0 3.625h9.166c.505 0 .917.408.917.906a.914.914 0 0 1-.917.907h-9.166a.914.914 0 0 1-.917-.907c0-.498.412-.906.917-.906Zm0 3.625h9.166c.505 0 .917.408.917.906a.914.914 0 0 1-.917.907h-9.166a.914.914 0 0 1-.917-.907c0-.498.412-.906.917-.906Z"/><path d="M0 0h30v30H0z"/></g></svg>
            {translate('Documento')}
            </button>
            <Modal open={open}
                onClose={closeModal}
                closeIcon={closeIcon}>
              <div className="modal-popup">
                <div className="header">
                  {translate('Documento')}
                </div>
                <div className="content">
                      {data.map(
                          (res, i) =>
                            <div className='victim-ref-list'>
                              <Row>
                                <Col md={2}>
                                  <a className="img" target="blank_" href={domain+res.identify_image}><img src={domain+res.identify_image} /></a>
                                </Col>
                                <Col md={10}>
                              {(res.identify_image_caption)?
                              <p>
                              <strong>{translate('Descripción')}</strong>: {res.identify_image_caption}
                              </p>
                              :''}
                              {(res.documentary_found && res.documentary_found_data)?
                              <p>
                              <strong>{translate('Procedencia')}</strong>: <Link target="_blank" to={"/docs/"+JSON.parse(res.documentary_found_data)[0]}>{res.documentary_found}</Link>
                              </p>
                              :''}
                                </Col>
                              </Row>
                            </div>
                      )}
                </div>
              </div>
            </Modal>
          </li>
          :''
        );
    }

}
import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import { Col, Row } from 'react-bootstrap'
import Modal from "react-responsive-modal";
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { translate } from '../../assets/js/functions';
import { domain, getVictimAudios } from '../../assets/js/api';
import parse from 'html-react-parser';

export default function VictimAudios(props) {

    const [victimId, setVictimId]     = useState(null);
    const [data, setData]     = useState(null);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        setVictimId(props.victimid);
        getVictimAudios(props.victimid).then(res => {
          setData(res.data.result ? res.data.result : null) }
      );
    }, [])

    const closeIcon = (
        <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    return dataReturn();

    function dataReturn(){
        return (
          data && data.length > 0 ?
            <li>
            <button onClick={() => setOpen(o => !o)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><g fill="none" fillRule="evenodd"><path fill="#A3233B" fillRule="nonzero" d="M2 9c0-1.655 1.345-3 3-3h12c1.655 0 3 1.345 3 3v12c0 1.655-1.345 3-3 3H5c-1.655 0-3-1.345-3-3V9Zm26.208-1.322c.487.263.792.769.792 1.322v12a1.501 1.501 0 0 1-2.334 1.247l-4.5-3-.666-.445V11.198l.666-.445 4.5-3a1.505 1.505 0 0 1 1.542-.075Z"/><path d="M0 0h30v30H0z"/></g></svg>
            {translate('Audiovisual')}
            </button>
            <Modal open={open}
                onClose={closeModal}
                closeIcon={closeIcon}>
              <div className="modal-popup">
                <div className="header">
                  {translate('Audiovisual')}
                </div>
                <div className="content">
                      {data.map(
                          (res, i) =>
                            <div className='victim-ref-list'>
                              <Row>
                                <Col md={4}>
                                  {(res.identify_image)?
                                  <a className="img" target="blank_" href={domain+res.identify_image}><img src={domain+res.identify_image} /></a>
                                  :''}
                                  {(res.audiovisual)?
                                  <video controls>
                                    <source src={domain+res.audiovisual} type="video/mp4"></source>
                                  </video>
                                  :''}
                                </Col>
                                <Col md={8}>
                              {(res.identify_image_caption)?
                              <p>
                              <strong>{translate('Descripción')}</strong>: {res.identify_image_caption}
                              </p>
                              :''}
                              {(res.audiovisual_data.length > 0 && res.audiovisual_data[0].rsc36)?
                              <p>
                              <strong>{translate('Descripción')}</strong>: {parse(res.audiovisual_data[0].rsc36)}
                              </p>
                              :''}
                              {(res.documentary_found && res.documentary_found_data)?
                              <p>
                              <strong>{translate('Procedencia')}</strong>: <Link target="_blank" to={"/docs/"+JSON.parse(res.documentary_found_data)[0]}>{res.documentary_found}</Link>
                              </p>
                              :''}
                                </Col>
                              </Row>
                            </div>
                      )}
                </div>
              </div>
            </Modal>
          </li>
          :''
        );
    }

}
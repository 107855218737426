import React, { useState } from 'react';
import classnames from 'classnames';
import { Col } from 'react-bootstrap';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import Oroibidea from '../../assets/images/LogoVectorial.svg';
import './assets/scss/styles.scss';
import { getCurrentLanguage, translate } from '../../assets/js/functions';

function Header(props){

    const [menu, setMenu] = useState(false)
    const history = useHistory()

    function _stateMenu(){
        props.open && props.open(!menu);
        setMenu(!menu);
    }

    return (
        <>
            <div className={props.className}>
                <Col className="header-logo" xs={10} md={6} lg={4}>
                    <Link to={'/'}><img className={classnames("logo", {min: history.location.pathname !== '/'})} src={props.inverted ? Oroibidea : Oroibidea} alt="logo" /></Link>
                    {/*
                        history.location.pathname.replace('/'+getCurrentLanguage, '').replace('/', '').split('/')[0] ?
                        {<span className='current-site'>
                            {translate(history.location.pathname.replace('/'+getCurrentLanguage, '').replace('/', '').split('/')[0])}
                        </span>
                        : null
                    */}
                </Col>
            </div>
            { history.location.pathname && history.location.pathname !== '/' ? <span id="menu-btn" onClick={() => _stateMenu()}>{<FaBars/>}</span> : null }
        </>
    );
}

export default withRouter(Header)
import court_records_data from 'react'
import 'reactjs-popup/dist/index.css';
import './assets/scss/styles.scss';
import { translate } from '../../assets/js/functions';
import VictimCourt from '../../components/Victim/VictimCourt';

export default function VictimRecorridoJudicial({data}) {

    return <>
        {(data.length > 0)?
        <>
        <h3>{translate('Recorrido judicial')}</h3>
        <table className="trecorrido">
            <thead>
                <tr>
                    <th></th>
                    <th><svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g transform="translate(-444, -785)">
                                    <g transform="translate(444, 785)">
                                        <polygon points="0 0 25 0 25 25 0 25"></polygon>
                                        <path d="M4.16666667,7.29166667 C4.16666667,6.14107344 5.09940677,5.20833333 6.25,5.20833333 L18.75,5.20833333 C19.9005932,5.20833333 20.8333333,6.14107344 20.8333333,7.29166667 L20.8333333,19.7916667 C20.8333333,20.9422599 19.9005932,21.875 18.75,21.875 L6.25,21.875 C5.09940677,21.875 4.16666667,20.9422599 4.16666667,19.7916667 L4.16666667,7.29166667 Z" id="Path" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <line x1="16.6666667" y1="3.125" x2="16.6666667" y2="7.29166667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <line x1="8.33333333" y1="3.125" x2="8.33333333" y2="7.29166667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <line x1="4.16666667" y1="11.4583333" x2="20.8333333" y2="11.4583333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <line x1="11.4583333" y1="15.625" x2="12.5" y2="15.625"  stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <line x1="12.5" y1="15.625" x2="12.5" y2="18.75"  stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </th>
                    <th><svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g transform="translate(-810, -783)">
                                    <g transform="translate(810, 783)">
                                        <polygon points="0 0 25 0 25 25 0 25"></polygon>
                                        <path d="M14.5833333,3.125 L14.5833333,7.29166667 C14.5833333,7.86696328 15.0497034,8.33333333 15.625,8.33333333 L19.7916667,8.33333333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M17.7083333,21.875 L7.29166667,21.875 C6.14107344,21.875 5.20833333,20.9422599 5.20833333,19.7916667 L5.20833333,5.20833333 C5.20833333,4.0577401 6.14107344,3.125 7.29166667,3.125 L14.5833333,3.125 L19.7916667,8.33333333 L19.7916667,19.7916667 C19.7916667,20.9422599 18.8589266,21.875 17.7083333,21.875 Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <line x1="9.375" y1="9.375" x2="10.4166667" y2="9.375" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <line x1="9.375" y1="13.5416667" x2="15.625" y2="13.5416667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        <line x1="9.375" y1="17.7083333" x2="15.625" y2="17.7083333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </th>
                </tr>
            </thead>
            <tbody>
            {data.map((res, index) =>
                <VictimCourt index={index} data={res} />
            )}
            </tbody>
        </table>
        </>
        :''}
    </>;


}
import React from 'react'
import 'reactjs-popup/dist/index.css';
import './assets/scss/styles.scss';
import { domain } from '../../assets/js/api';
import { formatDate, translate } from '../../assets/js/functions';

export default function VictimCourt(props) {

    const index = props.index;
    const court = props.data;

    return dataReturn();

    function dataReturn(){
        return court && court.documents_data.length > 0?
        <>
        <tr>
            <td><span className="red-num">{index+1}</span></td>
            <td>
                {court.documents_data[0].entry_date === null?
                translate('Sin fecha')
                :
                <>
                {court.documents_data[0].entry_date?formatDate(court.documents_data[0].entry_date):''}
                </>
                }
            </td>
            <td>
            {court.documents_data[0].document?
              <a className="doc" href={domain+court.documents_data[0].document}>
                {(court.documents_data[0].typology)?court.documents_data[0].typology:translate('Documento')}
               </a>
            :
            translate('Documento')
            }
            </td>
        </tr>
        </>
        :'';
    }

}
import React, { useEffect, useState } from 'react'
import { getApiCurrentLanguage, getCurrentLanguage, translate } from '../../assets/js/functions';
import SearcherInterviews from '../../components/Searcher/SearcherInterviews'
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss'
import { FaTimes, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import LearnMoreInterviews from '../../views/LearnMore/Interviews';
import { Container } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

export default function InterviewsList() {
    const title = translate("Entrevistas");
    const [modal, setModal] = useState(false)

  return (
    <div>
        <Breadcrumbs currentSite={title}/>
        <Helmet>
            <title>{title}</title>
        </Helmet>

        <div id='home' className="interviewHome">
            <div className='btn-moreinfo'>
                <Link to="#" className="btn-saber" onClick={() => setModal(!modal)}>{(getCurrentLanguage() != 'eu')?<FaPlus/>:''} {translate('Info')} {(getCurrentLanguage() == 'eu')?<FaPlus/>:''}</Link>
            </div>
            <div className="centered-home">
                <span className='searcher-text'>{translate('*Seleccione las sugerencias o busque en la transcripción de entrevistas')}</span>
                <SearcherInterviews placeholder={translate("Buscar")+"..."}/>
                <p className="btn-advanzed">
                </p>
            </div>
            <div className='buttons-middle'>
                <div className="buttons-home">
                    <Link to="/interviews/projects" className="btn button btn-cmn "><span>{ translate("Proyectos") }</span></Link>
                    <Link to="/interviews/explore" className="btn button btn-cmn"><span>{ translate("Explora") }</span></Link>
                </div>
            </div>
        </div>
        <Modal isOpen={modal}
            contentLabel="learnMoreModal"
            ariaHideApp={false}
        >
            <h2>{translate("Saber más")}</h2>
            <span className="closeModal" onClick={() => setModal(!modal)}><FaTimes/></span>
            <LearnMoreInterviews/>
        </Modal>
    </div>
    );
}

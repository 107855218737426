import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import parse from 'html-react-parser';
import './assets/scss/styles.scss';
import { domain } from '../../assets/js/api';
import { getPosterframe, translate } from '../../assets/js/functions';
import { BsPlayCircle } from 'react-icons/bs';
import VideoModal from '../Modals/VideoModal';


export default function SearchResult(props){

    const data = props.data;
    const [loadedThumbnail]     = useState(false);
    const [modal, setModal]     = useState(false);
    const [summary, setSummary] = useState(false);
    const [video, setVideo]     = useState(false);


    return (
        <div className="card">
            <Row className="search-item">
                <h5>{data.title}</h5>
                <Col md={5}>
                    <span className="text-item">
                        {parse(data.fragments[0].list_fragment)}
                    </span>
                </Col>
                <Col md={3}>
                    <div className="img-item">
                        {data.fragments && data.fragments[0].video_url ?
                                <div className={"video-thumbnail"+( loadedThumbnail ? "" : " loading-bg" )} onClick={() => {setModal(!modal); setVideo(data.fragments[0].video_url)}}>
                                    <img src={getPosterframe(domain + data.fragments[0].video_url)} alt={data.video}/>
                                    <BsPlayCircle/>
                                </div>
                        : <div className="loader1"><div></div><div></div></div>}
                    </div>
                </Col>
                <Col md={4}>
                        <div className="info-item">
                            <Row>
                            {
                                data.informant && data.informant[0] && !!data.informant.length && data.informant.map(
                                    (res, i) => <span key={i} className="name">{res.name} {res.surname}</span>
                                )
                            }
                            {
                                data.informant[0] && data.informant[0].location ?
                                <>
                                <Col className="icon" md={1}>
                                    <FaMapMarkerAlt/>
                                </Col>
                                <Col className="city-info" md={11}>
                                    <span className="city-label">{ translate("Población testimonio")+":" }</span>
                                    <span className="city-value">{ data.informant[0].location.split(',')[0] }</span>
                                </Col>
                                </>
                                : !data.informant[0] && <span className='name'>{ translate("Entrevista colectiva") }</span>
                            }
                                <div className='buttons'>
                                    <button onClick={() => setSummary(!summary)} className={"btn btn-interview"+(summary ? " active" : "")}>{ translate("Resumen") }</button>
                                     <button onClick={() => {setModal(!modal); setVideo(data.video); }} className="btn btn-interview">{ translate("Ver entrevista") } <BsPlayCircle/></button>
                                </div>
                        </Row>
                    </div>
                </Col>
                {
                    summary && data && data.abstract &&
                    <Col md={12}>
                        <hr/>
                        <span className='summary'>{ parse(data.abstract) }</span>
                    </Col>
                }
            </Row>
            { modal && video && data && <VideoModal modal={modal} audiovisual={video} onClose={(val) => setModal(val)}/> }
        </div>
    );
}

import React from 'react'
import { Link } from 'react-router-dom';
import { getCurrentLanguage } from '../../assets/js/functions';
import './assets/scss/styles.scss';

export default function Menu({onMenuClick, langSelected, items, languages}){

    function _handleClick(){
        onMenuClick && onMenuClick(true);
    }

    function _handleLanguageClick(lang){
        langSelected && langSelected(lang)
    }

    return (
        <div id="menu" onClick={() => _handleClick()}>
            <div className="bg-image"></div>
            <ul>
                { items &&
                    items.map(
                        item => <li key={item[0]} className="item"><Link onClick={() => _handleClick()} to={item[1]}>{ item[0] }</Link></li>
                    )
                }
                {
                    languages &&
                    <div id="menu-languages">
                        {
                            languages.map(
                                lang =>
                                    <a href={'/'+lang} langSelected={() => _handleLanguageClick(lang)} key={lang} className={"language"+(lang === getCurrentLanguage() ? " active" : "" )}>{lang}</a>
                            )
                        }
                    </div>
                }
            </ul>
        </div>
    );
}

import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getExplore, getExploreChildrens } from '../../assets/js/api';
import './assets/scss/styles.scss';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { colorShade, normalizeText, translate } from '../../assets/js/functions';

export default class Explore extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: null,
            dataChildren: null,
            children: ''
        }
    }

    componentDidMount(){
        this._getData();
    }

    _getData(valid = true){
        const id = this.props.match.params.id;
        if (!id) getExplore().then(res => this.setState({data: res.data.result}));
        else getExploreChildrens(id).then(res => res.data.result[0] ? console.log(res.data.result[0].childrens) : this._getData(true));
    }

    render() {
        const title = translate("Explora");
        const breadcrumbsStatic = [
            {name: translate("Entrevistas"), url: "/interviews"},
        ];
        return (
            <>
                <div id="explore">
                <Breadcrumbs key={this.props.match.params.id} currentId={this.props.match.params.id} staticData={breadcrumbsStatic} currentSite={title}/>
                        <>
                        <h2>{title}</h2>
                        <Helmet>
                            <title>{title}</title>
                        </Helmet>
                        <Container>
                            <Row>
                                {this.state.data ? this.state.data.map(
                                    arr => arr.result.map(
                                        res =>
                                            res.term !== "" ?
                                                <Col key={res.section_id} xs={12} md={3} className={"theme-item"}>
                                                    <div className='line-skew' style={{background: colorShade(normalizeText(res.term, true), 0)}}></div>
                                                    <Link to={"/interviews/explore/"+res.term_id}>{res.term}</Link>
                                                </Col>
                                            : null
                                    )
                                ): null}
                            </Row>
                        </Container>
                        </>
                </div>
            </>
        )
    }
}

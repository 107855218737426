import { domain, subtitlesUrl } from "../../assets/js/api";
import React, { useState, useRef, useEffect } from 'react'
import './assets/scss/styles.scss'
import {getCurrentLanguage} from '../../assets/js/functions'

export default function VideoModalMultiVideo({ value, index, current }) {

    const [hidden, setHidden] = useState(true);
    const videoRef = useRef();

    useEffect(() => {
        const hidden = index !== current;
        setHidden(hidden);
        if (videoRef.current) {
            if (hidden) {
                videoRef.current.pause();
            } else {
                videoRef.current.pause();
            }
        }
    }, [current])

    return (
        <>
        {!hidden?
        <video ref={videoRef} controls crossOrigin="anonymous">
            <source src={ domain + value.video} type="video/mp4"/>
            { value.subtitles && <track default kind="subtitles" src={ subtitlesUrl(value.subtitles)} srcLang={getCurrentLanguage()}/> }
            Your browser does not support the video tag.
        </video>
        :''}
        </>
    )
}

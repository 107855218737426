import React, { useState } from 'react';
import { FaSearch, FaTimesCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { getSearchResults, getTermName } from '../../assets/js/api';
import { groupBy } from '../../assets/js/functions';
import './assets/scss/styles.scss';

export default function Searcher(props){

    const [searchInput, setSearchInput]     = useState(null);
    const [data, setData]                   = useState([]);
    const [dataND, setDataND]               = useState([]);
    const [itemSelected, setItemSelected]   = useState(null);

    function _handleInputChange(text){
        setSearchInput(text.replace('\'', ''));
        setItemSelected(0);
        if (text.length >= 3){
            setData([]); setDataND([]);
            const results = getSearchResults(text).then(
            res => {
                return (res.data.result && res.data.result[0] ? res.data.result[0].result.concat(res.data.result[1].result) : null)
            });
            Promise.resolve(results).then(
                res => {
                    setData(data => [...data, res])
                    res.map(
                        mapped => {
                            if (JSON.parse(mapped.parent) && mapped.descriptor === "no"){
                                if (JSON.parse(mapped.parent)[0]){
                                    getTermName(JSON.parse(mapped.parent)[0]).then(result => {JSON.parse(result.data.result[0].parent)[0] === 'on1_1879' && setDataND(dataND => [...dataND, result.data.result[0]])})
                                }
                            }
                        }
                    )
                }
            )
        }
        if (text.length <3) setData([]);
    }

    function _handleInputKeyPress(key){
        // if (key === 13){
        //     if (itemSelected === 0){
        //         history.push(_urlSearch());
        //     }else{
        //         if (data){
        //             let item = data[itemSelected-1];
        //             history.replace({
        //                 pathname: '/explore/'+(item.descriptor === "no" ? item.parent.split('"')[1] : item.term_id)
        //             })
        //         }
        //     }
        // }
        if (key === 38) _itemSelected(false);
        if (key === 40) _itemSelected(true);
    }

    function _urlSearch(){
        if (isSearchable()) return "/interviews/search?query="+searchInput;
        return "#";
    }

    function _clearInput(){
        setSearchInput(null);
        setData(null);
    }

    function _itemSelected(val){
        if (isSearchable()){
            if (val && itemSelected < data.length) setItemSelected(itemSelected+1);
            if (!val && itemSelected > 0) setItemSelected(itemSelected-1);
        }
    }

    function isSearchable(){
        if (searchInput && searchInput.length >= 3) return true;
        return false;
    }


    return (
        <div className={"searcher"}>
            <div className={"input"+(itemSelected === 0 && isSearchable() ? " active" : "")}>
                <input onKeyDown={key => _handleInputKeyPress(key.which)} onChange={res => _handleInputChange(res.target.value)} value={searchInput || ''} type="text" placeholder={props.placeholder}/>
                { searchInput ? <div className="clear-input" onClick={() => _clearInput()}><FaTimesCircle/></div> : null }
            </div>
            <Link to={_urlSearch()} className="button"><FaSearch/></Link>
            { data && !!data.length ?
                <div className="searcher-results">
                    {Object.values(groupBy(data[0].concat(dataND).sort(), 'term')).map(
                        (res, i) => {
                            if (res[0].descriptor !== "no")
                                return <Link
                                        key={i}
                                        className={itemSelected === i+1 ? 'active' : ''}
                                        to={'/interviews/explore/'+res[0].term_id}>
                                            {res[0].term}
                                        </Link>
                            else if (res[0].descriptor === "no" && !res[0].parent)
                            return <Link
                                    key={i}
                                    className={itemSelected === i+1 ? 'active' : ''}
                                    to={'/interviews/explore/'+res[0].term_id}>
                                        {res[0].term}
                                    </Link>
                            }
                        )
                    }

                </div> : null
            }
        </div>
    )
}
import React, { useState } from 'react'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { translate } from '../assets/js/functions';

export default function MoreInfo({text}) {
    const [Open, setOpen] = useState(false);

    return (
        <>
        {!Open?
        <Link onClick={(event) => {event.preventDefault();setOpen(true)}} to="" class="transcripcion">{translate("Leer transcripción")}</Link>
        :
        <>
        <p class="transcripcion">
        {parse(text)}
        </p>
        <Link onClick={(event) => {event.preventDefault();setOpen(false)}} to="" class="transcripcion">{translate("Cerrar")}</Link>
        </>
        }
        </>
    )
}

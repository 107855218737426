import { getApiCurrentLanguage, getCurrentLanguage } from './functions';
import axios from "axios";

export const domain     = "https://dedalo.oroibidea.es";
const URL_SERVER        = domain+'/dedalo/lib/dedalo/publication/server_api/v1/json';
//const db_name           = 'web_oroibidea_pre';
//const code              = 'inm3014rtAw_testimonios_PRE';

const db_name           = 'dedalo4_public';
const code              = 'inm3014rtAw_testimonios';

const records           = '/records';
const fragment_locator  = '/fragment_from_index_locator';
const language          = getApiCurrentLanguage();
export const base_path  = '/dispromedia/web_oh/';


export function subtitlesUrl(subtitles) {
    return domain + subtitles + '&code='+code+'&db_name='+db_name;
}


export async function getVideoUrl(data){
    if(!data) return null;
    return await JSON.parse(data.indexations).map(
        val =>
        {return axios.post(
            URL_SERVER+
            fragment_locator+'?code='+code+
            '&db_name='+db_name+
            '&lang=lg-'+language+'&index_locator='+JSON.stringify(val)
        )})
}

export async function getFreeSearch(param){
    param = param.split("(")[0].trim();
    return await axios.post(
        URL_SERVER+"/free_search"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&rows_per_page=0"+
        "&q="+param+
        "&is_literal=true"+
        "&video_fragment=true"+
        "&fragment_terms=true"
    );
}

export async function getProjects(){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=project"+
        "&sql_filter=publication%3D'yes' AND name NOT LIKE '%ANULADO%'"+
        "&limit=0"
    );
}

export async function getDocsCatalogs(){
    var order = encodeURIComponent('collection_archive asc');
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=collections_archives"+
        '&order='+order+
        "&limit=0"

    );
}

export async function getDocsCatalog(catalog_id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=collections_archives"+
        "&section_id="+catalog_id
    );
}


export async function getDocsByPoblacio(search){
    var params = new URLSearchParams({
        sql_filter: "documentary_found_data is not null"+
        ((search.poblacion !== '')?" and toponymy like '%"+search.poblacion+"%'":'')+
        ((search.fondo !== '')?" and documentary_found_data='[\""+search.fondo+"\"]'":'')+
        ((search.tipo !== '')?" and format like '%"+search.tipo+"%'":''),

        resolve_portal: false,
        resolve_portals_custom: '{"audiovisual_data":"audiovisual"}',
        resolve_dd_relations: false

    }).toString()

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&"+params
    );
}

export async function getSearchPoblacionsDocs(){
    var table = 'documents';
    return await axios.post(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&table="+table+
        "&ar_fields=toponymy_data,toponymy"+
        "&sort=toponymy"+
        "&sql_filter=toponymy_data is not null"+
        "&group=toponymy_data"+
        "&lang=lg-"+language
    );
}


export async function getDocs(catalog_id){
    var order = encodeURIComponent('section_id asc');
    var params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"audiovisual_data":"audiovisual"}',
        resolve_dd_relations: false
    }).toString()


    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&sql_filter="+encodeURIComponent("documentary_found_data='[\""+catalog_id+"\"]'")+
        '&order='+order+
        "&limit=0"+
        "&"+params
    );
}

export async function getVictims(filtro){
    const searchTypes = {
        'golpe': {
            table: 'victimized_census',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: null
        },
        'economica': {
            table: 'economic_victims',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: null
        },
        'exilio': {
            table: 'exiled_victims',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: null
        },
        'deportacion': {
            table: 'deportation_victims_census',
            order: null,
            params: new URLSearchParams({
                sql_filter: "deportat is not null",
                resolve_portal: false,
                resolve_portals_custom: '{"camps_concentracio": "deportation_victims_concentration_camps"}',
                resolve_dd_relations: false
            }).toString()
        },
    }

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+searchTypes[filtro].table+
        "&limit=0"+
        ((searchTypes[filtro].order)?'&order='+searchTypes[filtro].order:'')+
        ((searchTypes[filtro].params)?'&'+searchTypes[filtro].params:'')
    );
}

export async function getSearchVictims(text){

    var table = 'informant';
    var order = encodeURIComponent('surname asc,name asc');
    //var search = encodeURIComponent("project is null and (surname like '%"+text+"%' or name like '%"+text+"%')")

    var searchFilter = text.split(' ').filter(res =>
        res.trim().length > 2
    ).map(res =>
        "(surname like '%"+res+"%' or name like '%"+res+"%')"
    );

    //var search = encodeURIComponent("project is null and ("+searchFilter.join(' and ')+")");
    var search = encodeURIComponent("(typology_data is null or typology_data not like '%\"2\"%') and ("+searchFilter.join(' and ')+")");

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&limit=0"+
        '&order='+order+
        '&sql_filter='+search
    );
}

export async function getSearchFosas(text){

    var table = 'graves_map';
    var order = encodeURIComponent('title asc');

    var searchFilter = text.split(' ').filter(res =>
        res.trim().length > 2
    ).map(res =>
        "(title like '%"+res+"%')"
    );

    var search = encodeURIComponent("documented_deceased is not null and ("+searchFilter.join(' and ')+")");

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&limit=0"+
        '&order='+order+
        '&sql_filter='+search
    ).then(res => {
        return res.data.result ? res.data.result : null
    });
}

export async function getSearchPrisiones(){
    const filter = [
        'on1_1461'
    ];

    var ar_calls = [];
    filter.forEach(value => {
        ar_calls.push({
            "id":value,
            "options":{
                "dedalo_get":"thesaurus_children",
                "ar_fields":[
                    "term_id",
                    "term",
                    "childrens"
                ],
                "lang":"lg-"+language,
                "term_id": value,
                "recursive": true,
                "only_descriptors": true,
                "remove_restricted": true
            }
        })
    });

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_calls="+JSON.stringify(ar_calls)+
        "&lang=lg-"+language+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        let join = [];
        res.forEach(value => {
            join = [...join, ...value.result];
        })
        return join;
    }).then(res => {
        return res.filter(value => (
            value.term !== null && value.term !== '' && value.childrens === null
        ));
    }).then(res => {
        return res.sort((a, b) =>
            a.term.localeCompare(b.term, getCurrentLanguage(), {
                ignorePunctuation: true,
                numeric: true
            })
        );
    });
}


export async function getSearchCampos(text){
    const filter = [
        'on1_652'
    ];

    var ar_calls = [];
    filter.forEach(value => {
        ar_calls.push({
            "id":value,
            "options":{
                "dedalo_get":"thesaurus_children",
                "ar_fields":[
                    "term_id",
                    "term",
                    "childrens"
                ],
                "lang":"lg-"+language,
                "term_id": value,
                "recursive": true,
                "only_descriptors": true,
                "remove_restricted": true
            }
        })
    });

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_calls="+JSON.stringify(ar_calls)+
        "&lang=lg-"+language+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        let join = [];
        res.forEach(value => {
            join = [...join, ...value.result];
        })
        return join;
    }).then(res => {
        return res.filter(value => (
            value.term !== null && value.term !== '' && value.childrens === null
        ));
    }).then(res => {
        return res.sort((a, b) =>
            a.term.localeCompare(b.term, getCurrentLanguage(), {
                ignorePunctuation: true,
                numeric: true
            })
        );
    });
}


export async function getSearchBatallones(text){
    const filter = [
        'on1_653',
        'on1_654',
        'on1_655'
    ];

    var ar_calls = [];
    filter.forEach(value => {
        ar_calls.push({
            "id":value,
            "options":{
                "dedalo_get":"thesaurus_children",
                "ar_fields":[
                    "term_id",
                    "term",
                    "childrens"
                ],
                "lang":"lg-"+language,
                "term_id": value,
                "recursive": true,
                "only_descriptors": true,
                "remove_restricted": true
            }
        })
    });

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_calls="+JSON.stringify(ar_calls)+
        "&lang=lg-"+language+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        let join = [];
        res.forEach(value => {
            join = [...join, ...value.result];
        })
        return join;
    }).then(res => {
        return res.filter(value => (
            value.term !== null && value.term !== '' && value.childrens === null
        ));
    }).then(res => {
        return res.sort((a, b) =>
            a.term.localeCompare(b.term, getCurrentLanguage(), {
                ignorePunctuation: true,
                numeric: true
            })
        );
    });
}

export async function getSearchVictimsAdv(filtro, search){

    const searchTypes = {
        'all': {
            table: 'informant',
            order: encodeURIComponent('surname asc,name asc'),
            params: new URLSearchParams({
                ar_fields: "section_id,surname,name",
                sql_filter: "publication = \"yes\" and (typology_data is null or typology_data not like '%\"2\"%')"+
                //" and project is null"+
                ((search.name !== '')?" and name like '%"+search.name+"%'":'')+
                ((search.surname !== '')?" and surname like '%"+search.surname+"%'":'')+
                ((search.genero !== '')?" and gender = '"+search.genero+"'":'')+
                ((search.nacimiento !== '')?" and birthplace like '%"+search.nacimiento+"%'":'')+
                ((search.residencia !== '')?" and location like '%"+search.residencia+"%'":'')+
                ((search.defuncion !== '')?" and dead_place like '%"+search.defuncion+"%'":'')
            }).toString()
        },
        'golpe': {
            table: 'victimized_census',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: new URLSearchParams({
                ar_fields: "victim,ref_victim_surname,ref_victim_name",
                sql_filter: "victim is not null and ref_victim_typology_data like '%\"1\"%'"+
                ((search.name !== '')?" and ref_victim_name like '%"+search.name+"%'":'')+
                ((search.surname !== '')?" and ref_victim_surname like '%"+search.surname+"%'":'')+
                ((search.genero !== '')?" and ref_victim_gender = '"+search.genero+"'":'')+
                ((search.nacimiento !== '')?" and ref_victim_birthplace like '%"+search.nacimiento+"%'":'')+
                ((search.residencia !== '' )?" and ref_victim_residence like '%"+search.residencia+"%'":'')+
                ((search.defuncion !== '')?" and ref_victim_deathplace like '%"+search.defuncion+"%'":'')
            }).toString()
        },
        'economica': {
            table: 'economic_victims',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: new URLSearchParams({
                ar_fields: "victim,ref_victim_surname,ref_victim_name",
                sql_filter: "victim is not null"+
                ((search.name !== '')?" and ref_victim_name like '%"+search.name+"%'":'')+
                ((search.surname !== '')?" and ref_victim_surname like '%"+search.surname+"%'":'')+
                ((search.genero !== '')?" and ref_victim_gender = '"+search.genero+"'":'')+
                ((search.nacimiento !== '')?" and ref_victim_birthplace like '%"+search.nacimiento+"%'":'')+
                ((search.residencia !== '')?" and ref_victim_residence like '%"+search.residencia+"%'":'')+
                ((search.defuncion !== '')?" and ref_victim_deathplace like '%"+search.defuncion+"%'":'')
            }).toString()
        },
        'exilio': {
            table: 'exiled_victims',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: new URLSearchParams({
                ar_fields: "victim,ref_victim_surname,ref_victim_name",
                sql_filter: "victim is not null"+
                ((search.name !== '')?" and ref_victim_name like '%"+search.name+"%'":'')+
                ((search.surname !== '')?" and ref_victim_surname like '%"+search.surname+"%'":'')+
                ((search.genero !== '')?" and ref_victim_gender = '"+search.genero+"'":'')+
                ((search.nacimiento !== '')?" and ref_victim_birthplace like '%"+search.nacimiento+"%'":'')+
                ((search.residencia !== '')?" and ref_victim_residence like '%"+search.residencia+"%'":'')+
                ((search.defuncion !== '')?" and ref_victim_deathplace like '%"+search.defuncion+"%'":'')
            }).toString()
        },
        'deportacion': {
            table: 'deportation_victims_census',
            order: encodeURIComponent('ref_cognom asc,ref_nom asc'),
            params: new URLSearchParams({
                ar_fields: "deportat,ref_cognom,ref_nom",
                sql_filter: "deportat is not null"+
                ((search.name !== '')?" and ref_nom like '%"+search.name+"%'":'')+
                ((search.surname !== '')?" and ref_cognom like '%"+search.surname+"%'":'')+
                ((search.genero !== '')?" and ref_genere = '"+search.genero+"'":'')+
                ((search.nacimiento !== '')?" and ref_lloc_naixement_valor like '%"+search.nacimiento+"%'":'')+
                //((search.residencia !== '')?" and ref_victim_residence like '%"+search.residencia+"%'":'')+
                ((search.defuncion !== '')?" and ref_lloc_defuncio_valor like '%"+search.defuncion+"%'":''),
            }).toString()
        },
        'presos': {
            table: 'victimized_census',
            order: encodeURIComponent('ref_victim_surname asc,ref_victim_name asc'),
            params: new URLSearchParams({
                ar_fields: "victim,ref_victim_surname,ref_victim_name",
                sql_filter: "victim is not null and ref_victim_typology_data like '%\"3\"%'"+
                ((search.name !== '')?" and ref_victim_name like '%"+search.name+"%'":'')+
                ((search.surname !== '')?" and ref_victim_surname like '%"+search.surname+"%'":'')+
                ((search.genero !== '')?" and ref_victim_gender = '"+search.genero+"'":'')+
                ((search.nacimiento !== '')?" and ref_victim_birthplace like '%"+search.nacimiento+"%'":'')+
                ((search.residencia !== '')?" and ref_victim_residence like '%"+search.residencia+"%'":'')+
                ((search.defuncion !== '')?" and ref_victim_deathplace like '%"+search.defuncion+"%'":'')
            }).toString()
        },
    }

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        //"&section_id="+section_id+
        "&lang=lg-"+language+
        "&table="+searchTypes[filtro].table+
        ((searchTypes[filtro].order)?'&order='+searchTypes[filtro].order:'')+
        ((searchTypes[filtro].params)?'&'+searchTypes[filtro].params:'')
    ).then(res => {
        return res.data.result ? res.data.result : null
    });

}


export async function getSearchSitesAdvFosa(filtro, search){

    const searchTypes = {
        'fosa': {
            table: 'graves_map',
            order: encodeURIComponent('title asc'),
            params: new URLSearchParams({
                ar_fields: "title,municipality,section_id",
                //sql_filter: "documented_deceased is not null"+
                sql_filter: "1 = 1"+
                ((search.name !== '')?" and title like '%"+search.name+"%'":'')+
                ((search.location !== '')?" and municipality like '%"+search.location+"%'":'')
            }).toString()
        },
    }

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        //"&section_id="+section_id+
        "&lang=lg-"+language+
        "&table="+searchTypes[filtro].table+
        ((searchTypes[filtro].order)?'&order='+searchTypes[filtro].order:'')+
        ((searchTypes[filtro].params)?'&'+searchTypes[filtro].params:'')
    );
}

export async function getFosa(section_id){
    var params = new URLSearchParams({
        resolve_portal: false,
        //resolve_portals_custom: '{"documented_deceased":"informant"}',
        resolve_portals_custom: '{"documented_deceased":"informant", "ref_interventions_data": "graves_map_interventions","identify_image_data":"image"}',
        resolve_dd_relations: false
    }).toString()

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&section_id="+section_id+
        "&lang=lg-"+language+
        "&"+params+
        "&table=graves_map"
    ).then(res => {
        return res.data.result[0] ? res.data.result[0] : null
    });
}

export async function getFosaByVictim(victimId){

    var params = new URLSearchParams({
        sql_filter: 'documented_deceased like \'%\\"'+victimId+'\\"%\''
    }).toString()

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&"+params+
        "&table=graves_map"
    ).then(res => {
        return res.data.result[0] ? res.data.result[0] : null
    });
}

export async function getSearchSitesAdv(fondo, search){
    const filter = {
        'prision': [
            'on1_1461'
        ],
        'campo': [
            'on1_652'
        ],
        'batallon': [
            'on1_653',
            'on1_654',
            'on1_655'
        ],
    }

    var ar_calls = [];
    filter[fondo].forEach(value => {
        ar_calls.push({
            "id":value,
            "options":{
                "dedalo_get":"thesaurus_children",
                /*"ar_fields":[
                    "term_id",
                    "term",
                    "childrens",
                    "location_name"
                ],*/
                "lang":"lg-"+language,
                "term_id": value,
                "recursive": true,
                "only_descriptors": true,
                "remove_restricted": true
            }
        })
    });

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_calls="+JSON.stringify(ar_calls)+
        "&lang=lg-"+language+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        let join = [];
        res.forEach(value => {
            join = [...join, ...value.result];
        })
        return join;
    })./*then(res => {
        return res.filter(value => (
            value.term !== null && value.term !== '' && value.childrens === null
        ));
    }).*/then(res => {
        if (search.name === '') {
            return res;
        }
        return res.filter(value =>
            value.term && value.term.toLowerCase().indexOf(search.name.toLowerCase()) > -1
        );
    }).then(res => {
        if (search.location === '') {
            return res;
        }
        return res.filter(value =>
            value.location_name && value.location_name.toLowerCase().indexOf(search.location.toLowerCase()) > -1
        );
    }).then(res => {
        return res.sort((a, b) =>
            a.term.localeCompare(b.term, getCurrentLanguage(), {
                ignorePunctuation: true,
                numeric: true
            })
        );
    });
}

export async function getTermIdByGroup(fondo){
    const filter = {
        'prision': [
            'on1_1461'
        ],
        'campo': [
            'on1_652'
        ],
        'batallon': [
            'on1_653',
            'on1_654',
            'on1_655'
        ],
    }

    var ar_calls = [];
    filter[fondo].forEach(value => {
        ar_calls.push({
            "id":value,
            "options":{
                "dedalo_get":"thesaurus_children",
                "ar_fields":[
                    "term_id",
                ],
                "lang":"lg-"+language,
                "term_id": value,
                "recursive": true,
                "only_descriptors": true,
                "remove_restricted": true
            }
        })
    });

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_calls="+JSON.stringify(ar_calls)+
        "&lang=lg-"+language+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        let join = [];
        res.forEach(value => {
            join = [...join, ...value.result];
        })
        return join;
    }).then(res =>
        res.map(value => value.term_id)
    );
}


export async function getSitesVictims() {
    const params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"court_records_data.documents_data": "document","movements_capture_data": "victimized_census_movements_capture", "court_records_data": "victimized_census_court_records"}',
        resolve_dd_relations: false,
        ar_fields: 'victim,ref_victim_surname,ref_victim_name,section_id,movements_capture_data'
    }).toString();


    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&"+params+
        "&table=victimized_census"+
        "&order="+encodeURIComponent('ref_victim_surname asc,ref_victim_name asc')+
        "&sql_filter=movements_capture_data is not null"+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    });
}


export async function getVictimsByPoblacio(poblacio_id){

    var table = 'informant';
    var order = encodeURIComponent('surname asc,name asc');
    //var search = encodeURIComponent("project is null and location_id='[\""+poblacio_id+"\"]'");
    var search = encodeURIComponent("(typology_data is null or typology_data not like '%\"2\"%') and location_id='[\""+poblacio_id+"\"]'");

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&limit=0"+
        '&order='+order+
        '&sql_filter='+search
    );
}

export async function getSearchVictim(id){

    var table = 'informant';

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&sql_filter=(typology_data is null or typology_data not like '%\"2\"%')"+
        "&section_id="+id
    );
}

export async function getSearchPoblacionsVictimes(text){
    var table = 'informant';
    var search = new URLSearchParams({
        //sql_filter: "project is null and location_id is not null and location_municipality like '%"+text+"%'",
        sql_filter: "(typology_data is null or typology_data not like '%\"2\"%') and location_id is not null and location_municipality like '%"+text+"%'",
    }).toString();
    return await axios.post(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&table="+table+
        "&ar_fields=location_id,location_municipality"+
        "&sort=location_municipality"+
        "&group=location_id"+
        "&lang=lg-"+language+
        "&"+search
    );
}

export async function getSearchResults(text){

    var ar_calls = [
    {
        "id":"onomastic",
        "options":{
            "dedalo_get":"records",
            "table":"ts_onomastic",
            "ar_fields":[
                "term_id",
                "term",
                "descriptor",
                "parent"
            ],
            "sql_filter":"term like '%"+text+"%'",
            "lang":"lg-"+language,
            "resolve_portal": true,
            "resolve_portals_custom": {"children": "ts_onomastic"}
        }
    },
    {
        "id":"thematic",
        "options":{
            "dedalo_get":"records",
            "table":"ts_thematic",
            "ar_fields":[
                "term_id",
                "term",
                "descriptor",
                "parent"
            ],
            "sql_filter":"term like '%"+text+"%'",
            "lang":"lg-"+language,
            "resolve_portal": true,
            "resolve_portals_custom": {"children": "ts_thematic"}
        }
    }
    ];

    ar_calls = "%5B%7B%22id%22%3A%22onomastic%22%2C%22options%22%3A%7B%22dedalo_get%22%3A%22records%22%2C%22table%22%3A%22ts_onomastic%22%2C%22ar_fields%22%3A%5B%22term_id%22%2C%22term%22%2C%22descriptor%22%2C%22parent%22%5D%2C%22sql_filter%22%3A%22term%20like%20'%25"+text+"%25'%22%2C%22lang%22%3A%22lg-"+language+"%22%2C%22order%22%3A%22term%22%2C%22resolve_portal%22%3Atrue%2C%22resolve_portals_custom%22%3A%7B%22children%22%3A%22ts_onomastic%22%7D%7D%7D%2C%7B%22id%22%3A%22thematic%22%2C%22options%22%3A%7B%22dedalo_get%22%3A%22records%22%2C%22table%22%3A%22ts_thematic%22%2C%22ar_fields%22%3A%5B%22term_id%22%2C%22term%22%2C%22descriptor%22%2C%22parent%22%5D%2C%22sql_filter%22%3A%22term%20like%20'%25"+text+"%25'%22%2C%22lang%22%3A%22lg-"+language+"%22%2C%22order%22%3A%22term%22%2C%22resolve_portal%22%3Atrue%2C%22resolve_portals_custom%22%3A%7B%22children%22%3A%22ts_thematic%22%7D%7D%7D%5D"

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_calls="+ar_calls+
        "&lang=lg-"+language+
        "&limit=0"
    );
}


export async function getExplore(){

    const ar_calls = [
        {
            "id":"onomastic",
            "options":{
                "dedalo_get":"records",
                "table":"ts_onomastic",
                "sql_filter":"parent='[\"hierarchy1_270\"]' AND term NOT LIKE \"%[%\"",
                "lang":"lg-"+language
            }
        },
        {
            "id":"thematic",
            "options":{
                "dedalo_get":"records",
                "table":"ts_thematic",
                "sql_filter":"parent='[\"hierarchy1_1\"]' AND term NOT LIKE \"%[%\"",
                "lang":"lg-"+language
            }
        }
        ];

        return await axios.post(
            URL_SERVER+
            "/combi"+
            "?code="+code+
            "&db_name="+db_name+
            "&ar_calls="+JSON.stringify(ar_calls)+
            "&lang=lg-"+language+
            "&limit=0"
        );
}

export async function getExploreChildrens(id, char = null){
        const table = id.includes("ts1_") ? "ts_thematic" : "ts_onomastic";
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&lang=lg-"+language+
            "&table="+table+
            "&order=term"+
            '&sql_filter=parent%3D%22%5B%5C%22'+id+'%5C%22%5D%22'+
            (char ? "%20AND%20term%20like%20%22"+char+"%25%22" : "")+
            "&limit=0"
        );
}

export async function getHasInterview(term){
    const params = new URLSearchParams({
        term_id: term.term_id,
        recursive: true,
        only_descriptors: true,
        remove_restricted: true
    }).toString()


    return await axios.post(
        URL_SERVER+
        "/thesaurus_children"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        ((params)?'&'+params:'')
    ).then(res => {
        //return true;
        const relations = JSON.parse(term.indexation);
        if (relations && relations.filter(value => value.section_tipo.includes('rsc')).length > 0) {
            return true;
        }
        let result = false;
        res.data.result.forEach(value => {
            const relations = JSON.parse(value.indexation);
            if (relations && relations.filter(value => value.section_tipo.includes('rsc')).length > 0) {
                result = true;
            }
        });
        return result;
    });
}

export async function getExploreTitle(term_id){
        const table = term_id.includes("ts1_") ? "ts_thematic" : "ts_onomastic";
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&lang=lg-"+language+
            "&ar_fields=[\"term\", \"childrens\", \"indexation\"]"+
            "&table="+table+
            "&sql_filter=term_id%3D'"+term_id+"'"+
            "&limit=0"
        );
}

export async function getTermName(term_id){
    return await axios.post(
        URL_SERVER+
        "/thesaurus_term"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&ar_term_id="+term_id
    );
}

export async function getTermsCombined(terms){
    return axios.post(
        URL_SERVER+
        "/thesaurus_term"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&ar_term_id="+terms.toString()+
        "&combine=combined"
    );
}

export async function getProject(section_id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=project"+
        "&lang=lg-"+language+
        "&section_id="+section_id
    );
}


export async function getVictim(section_id, filtro){
    const searchTypes = {
        'golpe': {
            table: 'victimized_census',
            params: new URLSearchParams({
                sql_filter: "victim = '[\""+section_id+"\"]' and ref_victim_typology_data like '%\"1\"%'",
                resolve_portal: false,
                resolve_portals_custom: '{"bibliographic_references_data":"bibliographic_references","bibliographic_references_data.publications_data":"publications","court_records_data.documents_data": "document", "court_records_data": "victimized_census_court_records"}',
                resolve_dd_relations: false
            }).toString()
        },
        'economica': {
            table: 'economic_victims',
            params: new URLSearchParams({
                sql_filter: "victim = '[\""+section_id+"\"]'",
            }).toString()
        },
        'exilio': {
            table: 'exiled_victims',
            params: new URLSearchParams({
                sql_filter: "victim = '[\""+section_id+"\"]'",
            }).toString()
        },
        'deportacion': {
            table: 'deportation_victims_census',
            params: new URLSearchParams({
                sql_filter: "deportat is not null and deportat = '[\""+section_id+"\"]'",
                resolve_portal: false,
                resolve_portals_custom: '{"deportat":"informant", "camps_concentracio": "deportation_victims_concentration_camps"}',
                resolve_dd_relations: false
            }).toString()
        },
        'prision': {
            table: 'victimized_census',
            params: new URLSearchParams({
                sql_filter: "victim = '[\""+section_id+"\"]' and ref_victim_typology_data like '%\"3\"%'",
                resolve_portal: false,
                resolve_portals_custom: '{"bibliographic_references_data":"bibliographic_references","bibliographic_references_data.publications_data":"publications","court_records_data.documents_data": "document","movements_capture_data": "victimized_census_movements_capture", "court_records_data": "victimized_census_court_records"}',
                resolve_dd_relations: false
            }).toString()
        },
    }

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        //"&section_id="+section_id+
        "&lang=lg-"+language+
        "&table="+searchTypes[filtro].table+
        ((searchTypes[filtro].params)?'&'+searchTypes[filtro].params:'')
    );
}

export async function getVictimDocs(victim_id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&sql_filter="+encodeURIComponent("victims_data like '%\""+victim_id+"\"%' and format like '%Pre_documento%'")+
        "&limit=0"
    );
}

export async function getVictimImgs(victim_id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&sql_filter="+encodeURIComponent("victims_data like '%\""+victim_id+"\"%'")+
        "&limit=0"
    );
}

export async function getVictimPubs(victim_id){

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&sql_filter="+encodeURIComponent("victims_data like '%\""+victim_id+"\"%'")+
        "&limit=0"
    );
}

export async function getVictimAudios(victim_id){
    var params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"audiovisual_data":"audiovisual"}',
        resolve_dd_relations: false
    }).toString()
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&sql_filter="+encodeURIComponent("victims_data like '%\""+victim_id+"\"%' and audiovisual is not null")+
        "&limit=0"+
        "&"+params
    );
}

export async function getVictimPhotos(victim_id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=documents"+
        "&sql_filter="+encodeURIComponent("victims_data like '%\""+victim_id+"\"%' and format like '%Pre_fotografía%'")+
        "&limit=0"
    );
}

export async function getVictimInterviews(victim_id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=informant"+
        "&sql_filter=(typology_data is null or typology_data not like '%\"2\"%') and indexation is not null"+
        "&ar_fields=indexation"+
        "&resolve_portal=false"+
        "&resolve_dd_relations=false"+
        "&section_id="+victim_id+""
    ).then(res => {
        if (res.data.result && res.data.result.length === 1) {
            return JSON.parse(res.data.result[0].indexation);
        }
        return [];
    });
}

export async function getVictimInterviewsIndexLocator(index_locator){
    return await axios.post(
        URL_SERVER+
        fragment_locator+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&fragment_terms=true"+
        "&index_locator="+JSON.stringify(index_locator)

    );
}

export async function getParents(currentId){
    return await axios.post(
        URL_SERVER+
        "/thesaurus_parents"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_fields=term"+
        "&lang=lg-"+language+
        "&term_id="+currentId+
        "&order=ASC"
    );
}

export async function getInterviews(projectId){
    let sql = "&sql_filter=project%3D%22%5B%5C%22"+projectId+"%5C%22%5D%22";

    var params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"audiovisual":"audiovisual"}',
        resolve_dd_relations: false
    }).toString();
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_fields=[\"section_id\", \"audiovisual\", \"informant\", \"abstract\", \"title\"]"+
        "&table=interview"+
        "&lang=lg-"+language+
        sql+
        '&'+params+
        "&limit=0"
    );
}

export async function getInformant(id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=informant"+
        "&sql_filter=typology_data like '%\"2\"%'"+
        "&lang=lg-"+language+
        "&section_id="+id
    );
}

export async function getAudiovisual(id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=audiovisual"+
        "&lang=lg-"+language+
        "&section_id="+id
    );
}

export async function getThematicIndexations(id){
    const table = id.includes("ts1_") ? "ts_thematic" : "ts_onomastic";
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table="+table+
        "&ar_fields=indexation"+
        "&lang=lg-"+language+
        "&sql_filter=term_id%3D%22"+id+"%22"
    );
}

export async function getFragmentfromIndexLocator(index_locator){

    const ar_calls = [
        {
            "id":"fragment_from_index_locator",
            "options":{
                "dedalo_get":"fragment_from_index_locator",
                "index_locator": index_locator,
                "fragment_terms": true,
                "lang":"lg-"+language
            }
        },
        {
            "id":"interview",
            "options":{
                "dedalo_get":"records",
                "table":"interview",
                "section_id": JSON.parse(index_locator).section_top_id,
                "lang":"lg-"+language
            }
        }
        ];

        return await axios.post(
            URL_SERVER+ //  https://inm.render.es/dedalo/lib/dedalo/publication/server_api/v1/json
            "/combi"+
            "?code="+code+ //   inm3014rtAw_testimonios
            "&db_name="+db_name+ //   dedalo4_public
            "&ar_calls="+JSON.stringify(ar_calls)+
            "&lang=lg-"+language+
            "&limit=0"
        );
}

export async function getTestimoniosMapsMarks(){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=ts_onomastic"+
        "&ar_fields=[\"space\", \"term\", \"term_id\", \"external_uri\", \"location\", \"location_name\", \"location_map\"]"+
        "&order=space"+
        "&sql_filter=parent%3D%22%5B%5C%22on1_1879%5C%22%5D%22"+
        "&lang=lg-"+language
    );
}

export async function getVictimasMapsMarks(filtro){
    const searchTypes = {
        'golpe': {
            table: 'victimized_census',
            order: 'ref_victim_residence_geojson',
            params: new URLSearchParams({
                sql_filter: "victim is not null",
            }).toString()
        },
        'economica': {
            table: 'economic_victims',
            order: 'ref_victim_residence_geojson',
            params: new URLSearchParams({
                sql_filter: "victim is not null",
            }).toString()
        },
        'exilio': {
            table: 'exiled_victims',
            order: 'ref_victim_residence_geojson',
            params: new URLSearchParams({
                sql_filter: "victim is not null",
            }).toString()
        },
        'deportacion': {
            table: 'deportation_victims_census',
            order: null,
            params: new URLSearchParams({
                sql_filter: "deportat is not null",
                resolve_portal: false,
                resolve_portals_custom: '{"deportat":"informant"}',
                resolve_dd_relations: false
            }).toString()
        },
    }

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table="+searchTypes[filtro].table+
        //"&ar_fields=[\"ref_victim_residence_geojson\", \"information_sources_uri\", \"victim\", \"ref_victim_name\", \"ref_victim_surname\"]"+
        //"&order=ref_victim_residence_geojson"+
        "&lang=lg-"+language+
        ((searchTypes[filtro].order)?'&order='+searchTypes[filtro].order:'')+
        ((searchTypes[filtro].params)?'&'+searchTypes[filtro].params:'')
    );
}

export async function getNaziMaps(){
    const params = new URLSearchParams({
        term_id: 'on1_601',
        recursive: true,
        only_descriptors: true,
        remove_restricted: true
    }).toString()

    return await axios.post(
        URL_SERVER+
        "/thesaurus_children"+
        "?code="+code+
        "&db_name="+db_name+
        "&table=ts_onomastic"+
        //"&sql_filter=space_geojson is not null"+
        "&lang=lg-"+language+
        ((params)?'&'+params:'')
    );
}

export async function getSitiosMaps(sites){

    const filter = {
        'carceles': [
            'on1_1461'
        ],
        'campos': [
            'on1_652'
        ]
    }

    const params = new URLSearchParams({
        term_id: filter[sites],
        recursive: true,
        only_descriptors: true,
        remove_restricted: true
    }).toString()

    return await axios.post(
        URL_SERVER+
        "/thesaurus_children"+
        "?code="+code+
        "&db_name="+db_name+
        "&table=ts_onomastic"+
        //"&sql_filter=space_geojson is not null"+
        "&lang=lg-"+language+
        ((params)?'&'+params:'')
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        return res.filter(value => (
            value.space_geojson && value.space_geojson !== null && value.space_geojson !== ''
        ));
    }).then(res => {
        return res.map(value => {
            value.coordinates = JSON.parse(value.space_geojson)[0].layer_data.features[0].geometry.coordinates;
            return value;
        });
    });
}

export async function getVictimasNaziMapsMarks(camp){

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=deportation_victims_census"+
        //"&order=ref_camps_concentracio_camp_geojson"+
        //"&sql_filter=ref_camps_concentracio_camp_geojson is not null"+
        "&sql_filter=ref_camps_concentracio_camp_dato like '%"+camp+"%'"+
        "&lang=lg-"+language+
        "&limit=10"
    );
}

export async function getFosasMapsMarks(){
    var params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"documented_deceased":"informant"}',
        resolve_dd_relations: false
    }).toString()

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=graves_map"+
        "&sql_filter=location_geojson is not null"+
        "&lang=lg-"+language+
        '&'+params+
        "&limit=0"
    ).then(res => {
        return res.data.result?res.data.result:null
    }).then(res => {
        if (res === null) {
            return null;
        }
        return res.map(value => {
            value.location = JSON.parse(value.location_geojson)[0].layer_data.features[0].geometry.coordinates
            return value;
        });
    });
}

export async function getReelTermsByAudiovisual(avId){
    return await axios.post(
        URL_SERVER+
        "/reel_terms"+
        "?code="+code+
        "&db_name="+db_name+
        "&av_section_id="+avId+
        "&lang=lg-"+language
    );
}


export async function isChildren(parent, id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=ts_onomastic"+
        "&ar_fields=[\"term_id\"]"+
        "&sql_filter=term_id%20%3D%22"+id+"%22%20AND%20parent%3D%22%5B%5C%22"+parent+"%5C%22%5D%22"+
        "&lang=lg-"+language
    );



    //audiovisual
        //documents
        //image
    //interview
}

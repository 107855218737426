import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import './assets/scss/styles.scss';
import { translate, leafletNumberIcon } from '../../assets/js/functions';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import VictimPrision from '../../components/Victim/VictimPrision';
import { getTermName } from '../../assets/js/api';


export default function VictimPrisionMap(props) {

    const prision = props.prision;
    const victimId = props.victimId;
    const sitesConvert = props.sitesConvert;
    let L = null;
    let mapGroups = [];

    var map = null;

    function backHandler() {
        props.close();
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.js";
        script.async = true;
        script.onload = () => scriptLoaded();
        document.body.appendChild(script);

        const style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
        document.head.appendChild(style);
    }, [])

    function scriptLoaded(){
        L = window.L;

        map = new L.map(document.getElementById('mapa'));
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
        // Target's GPS coordinates.
        var target = L.latLng('40.416775', '-3.703790');
        map.setView(target, 6);
    }

    function mapZoom(data) {

        let termsIds = [];
        let info = JSON.parse(data.detention_center_data);
        if (info !== null && info.length > 0) {
            termsIds.push(info[0]);
        }
        info = JSON.parse(data.destination_center_data);
        if (info !== null && info.length > 0) {
            termsIds.push(info[0]);
        }
        info = JSON.parse(data.origin_center_data);
        if (info !== null && info.length > 0) {
            termsIds.push(info[0]);
        }
        if (termsIds.length === 0) {
            return;
        }

        mapGroups.forEach(value => {
            map.removeLayer(value);
        })

        if (typeof mapGroups[data.section_id] !== 'undefined') {
            mapGroups[data.section_id].addTo(map);
            map.fitBounds(mapGroups[data.section_id].getBounds(), {
                maxZoom: 10
            });
            return;
        }

        getTermName(termsIds.join(',')).then(res => {
            if (res.data.result === null || res.data.result.length == 0) {
                return;
            }
            const entrys = res.data.result;
            let linePoints = [];
            mapGroups[data.section_id] = L.featureGroup();
            entrys.forEach((value, index) => {
                if (value.space_geojson == null || value.space_geojson === '') {
                    return;
                }
                const location = JSON.parse(value.space_geojson)[0].layer_data.features[0].geometry.coordinates;
                const target = L.latLng(location[1], location[0]);
                L.marker(target, {
                    icon: leafletNumberIcon(index+1)
                })
                .addTo(mapGroups[data.section_id]);
                linePoints.push(target);
            });
            const firstpolyline = new L.Polyline(linePoints, {
                color: '#a3233a'
            });
            firstpolyline.addTo(mapGroups[data.section_id]);
            mapGroups[data.section_id].addTo(map);
            map.fitBounds(linePoints, {
                maxZoom: 10
            });
        });

    }


    return <div className="victim-card-map">
        <Row>
          <Col md={5} className="map-wrapper">
            <div id="mapa"></div>
          </Col>
          <Col md={7} className="victim-card">
            <Link className="back" to={'/search/victim/'+victimId}  onClick={(event) => {event.preventDefault();backHandler()}}>{translate("Volver")}</Link>
            <h3>{translate('Lugares de encierro')}</h3>
            <table className="trecorrido">
                <thead>
                    <tr>
                        <th><svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-444, -785)">
                                        <g transform="translate(444, 785)">
                                            <polygon points="0 0 25 0 25 25 0 25"></polygon>
                                            <path d="M4.16666667,7.29166667 C4.16666667,6.14107344 5.09940677,5.20833333 6.25,5.20833333 L18.75,5.20833333 C19.9005932,5.20833333 20.8333333,6.14107344 20.8333333,7.29166667 L20.8333333,19.7916667 C20.8333333,20.9422599 19.9005932,21.875 18.75,21.875 L6.25,21.875 C5.09940677,21.875 4.16666667,20.9422599 4.16666667,19.7916667 L4.16666667,7.29166667 Z" id="Path" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <line x1="16.6666667" y1="3.125" x2="16.6666667" y2="7.29166667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                            <line x1="8.33333333" y1="3.125" x2="8.33333333" y2="7.29166667" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                            <line x1="4.16666667" y1="11.4583333" x2="20.8333333" y2="11.4583333" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                            <line x1="11.4583333" y1="15.625" x2="12.5" y2="15.625"  stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                            <line x1="12.5" y1="15.625" x2="12.5" y2="18.75"  stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></line>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </th>
                        <th>
                            <div className="loc-head">{translate('Procedencia')}</div>
                        </th>
                        <th>
                            <div className="loc-head">{translate('Reclusión')}</div>
                        </th>
                        <th>
                            <div className="loc-head">{translate('Destino')}</div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {prision.map((res, index) =>
                    <VictimPrision index={index} data={res} sitesConvert={sitesConvert} hasRefs={false} view={mapZoom} />
                )}
                </tbody>
            </table>
          </Col>
        </Row>
    </div>;

}